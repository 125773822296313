import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import UniqueID from 'uniqid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SelectedListItem from './SelectedListItem';
import SelectedListContent from './RoutesContainer';
import { Avatar, Breadcrumbs, Button, Grid, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import RoutesContainer from './RoutesContainer';
import { useMsal } from "@azure/msal-react";
import store from "store/store";
import { setUser } from 'store/session';
import { TrackingPDMConnection } from "./RestService.js";

const drawerWidth = 240;
const toolbarHeight = 64;

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden'
  },
  topBarLeftFlex: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: "1"
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    minHeight: toolbarHeight,
    maxHeight: toolbarHeight,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '39px 8px',
    ...theme.mixins.toolbar,
  },
  toolbarCompanyLogo: {
    display: 'flex',
    maxHeight: '39px',
    maxWidth: '75px',
    edge: "start"
  },
  toolbarCompanyLogoDiv: {
    display: 'flex',
    justifyContent: "flex-start",
    padding: "18px 22px"
    // flex: "1"
  },
  quickLinksToolbar: {
    background: '#f2f2f2',
    minHeight: toolbarHeight / 2,
  },
  homeIcon: {
    maxHeight: '28px'
  },
  quickLink: {
    color: '#0071b9',
    paddingRight: '10px'
  },
  underline: {
    textDecorationLine: 'underline'
  },
  appBar: {
    display: "block",
    position: "absolute", // fixed
    top: "0px",
    height: "auto",
    zIndex: theme.zIndex.drawer + 1,
    background: '#FFFFFF',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuIconOpen: {
    color: "#6c6c6c"
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    color: "#000000",
    fontWeight: 'bold',
    fontSize: "27px"
  },
  drawerPaper: {
    position: 'relative',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    position: 'relative',
    height: 'auto',
    minHeight: "100vh",
    overflowY: 'hidden',
    //paddingTop: toolbarHeight + (toolbarHeight / 2) + (toolbarHeight / 3),
    paddingTop: toolbarHeight/2
  },
  contentDiv: {
    height: 'hidden',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  breadCrumbText: {
    fontSize: "calc(0.8vw+1.5vh)",
    cursor: "pointer"
  }
}));

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedTitle, setSelectedTitle] = useState("Dashboard");
  const [currentPath, setCurrentPath] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [userName, setUserName] = useState("");
  const [homeBtn, homePressed] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { instance, accounts } = useMsal();
  let [testName, setTestName] = useState("");
  let [testDescription, setTestDescription] = useState("");
  const forceUpdate = useForceUpdate();

  const handleHomePressed = (index) => {
    homePressed(index);
  };
  const handleLogout = (instance) => {
    sessionStorage.removeItem("pdmUsername");
    sessionStorage.removeItem("pdmPassword");
    instance.logoutRedirect().catch(e => {
      console.error(e);
  });
  };
  const handleSetSelectedIndex = (index, tName = "", tDesc = "") => {
    setSelectedIndex(index);
    setTestName(tName);
    setTestDescription(tDesc);
  };
  const handleSetSelectedTitle = (title) => {
    setSelectedTitle(title);
  };
  const handleSetCurrentPath = (path) => {
    setCurrentPath(path);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUserClose = () => {
      setAnchorEl(null);
  };
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  useEffect(() => {
    const state = store.getState();
    if (Object.keys(state.sessionState.user).length === 0)
    {
      store.dispatch(setUser(accounts[0]));
      setUserName(accounts[0].name);
    }
  },[]);

  const trackPDMConnection = async () => {
    const result = await TrackingPDMConnection(instance);
    console.log("result::=="+JSON.stringify(result));
    if(result=="Success")
    {
      document.getElementById("Success").style.display="block";
      document.getElementById("Failed").style.display="none";
    }
    else
    {
      document.getElementById("Success").style.display="none";
      document.getElementById("Failed").style.display="block";
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      trackPDMConnection();
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarCompanyLogoDiv}>
          <table width="100%">
            <tr>
              <td width="80%" align="left">
              <img src={process.env.PUBLIC_URL + "/Kone logo.png"} className={classes.toolbarCompanyLogo} />
              </td>
              <td width="20%" align="right">
              <b><a href="https://pdm.konenet.com/documents/51000007D38" target="_blank" rel="noreferrer">Help</a></b>
              </td>
            </tr>
          </table>
        </div>

        <div className={classes.toolbarCompanyLogoDiv}>
          <b>PDM Connection</b> &nbsp;&nbsp;
          
          <div id="Success">
          <Tooltip title={"Status: Online"}>
            <div style={{display: "flex", width: "20px", height: "20px", backgroundColor: "green", borderRadius: "50%"}}></div>
          </Tooltip>
          </div>
          <div id="Failed" style={{display: "none"}}>
          <Tooltip title={"Status: Offline"}>
            <div style={{display: "flex", width: "20px", height: "20px", backgroundColor: "red", borderRadius: "50%"}}></div>
          </Tooltip>
          </div>
        </div>

        <Grid container onClick={handleUserClick} spacing={1} style={{ paddingBottom: "14px", paddingTop: "24px", paddingLeft: "30px" } }>
        <Grid item style={{textAlign: "center"}}>
        <Avatar
          style={{ marginRight: "14px" }}
          alt={userName}
          src={process.env.PUBLIC_URL + "/avatar.png"}
          //"https://st3.depositphotos.com/1767687/16607/v/600/depositphotos_166074422-stock-illustration-default-avatar-profile-icon-grey.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500"
          />
        </Grid>
        <Grid item style={{textAlign: "left"}}>
        <Typography variant="body2"> Logged in as</Typography>
        <Typography variant="body1"> {userName}</Typography>
        </Grid>
        <Grid item style={{textAlign: "center"}}>
            {anchorEl === null ? <ExpandMore /> : <ExpandLess />}
        </Grid>
        </Grid>
        {/* <div className={classes.toolbarIcon}>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div> */}

        <Divider />
        <SelectedListItem forceUpdate={forceUpdate} indexHandler={handleSetSelectedIndex} titleHandler={handleSetSelectedTitle} homeHandler={homeBtn} pathHandler={handleSetCurrentPath} />
        <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted={false}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleUserClose}
                PaperProps={{
                    style: {
                      transform: 'translateX(0) translateY(-6px)',
                    },
                  }}
            >
              <MenuItem onClick={() => {handleLogout(instance);}}>Logout</MenuItem>
          </Menu>
      </Drawer>
      <main className={classes.content}>
        {/* <div className={classes.appBarSpacer} />
        <div className={classes.appBarSpacer} /> */}
        {/* Main content */}
        <div className={classes.contentDiv}>
          <RoutesContainer
            selectedContentIndex={selectedIndex}
            indexHandler={handleSetSelectedIndex}
            titleHandler={handleSetSelectedTitle}
            homeHandler={homeBtn}
            pathHandler={handleSetCurrentPath}
            testName={testName}
            testDescription={testDescription} />
        </div>
      </main>

    </div>
  );
}
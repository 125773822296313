import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { GlobalUseStyles } from './GlobalUseStyles';
import clsx from 'clsx';
import { PDMLogin } from './RestService';
import { useMsal } from '@azure/msal-react';   
import { useSnackbar } from 'react-simple-snackbar';
import { errorSnackbarOptions, successSnackbarOptions, warningSnackbarOptions } from './SnackbarOptions';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import config from "../config";
import CryptoJS from 'crypto-js';

const useStyles = makeStyles((theme) => ({

}));

export default function PDMSignIn(props) {
const classes = useStyles();
const globalClasses = GlobalUseStyles();
const { instance, accounts } = useMsal();

const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar(successSnackbarOptions());
const [openErrorSnackbar, closeErrorSnackbar] = useSnackbar(errorSnackbarOptions());
const [openWarningSnackbar, closeWarningSnackbar] = useSnackbar(warningSnackbarOptions());

const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    var formData = {};
    data.forEach(function(value, key){
      formData[key] = value;
    });  
    formData['username'] = formData['username'].toLowerCase();    
        sessionStorage.setItem("pdmUsername", formData['username']);
        const encryptedPasswordData = encryptPassword(formData['password'], config.encryptSKey);
        console.log(encryptedPasswordData);
        sessionStorage.setItem("pdmPassword", encryptedPasswordData);
        
    console.log("Call login check");
    loginCheck(formData);
};

    // Test query to PDM to confirm that user info is correct. If is correct, set session storage parameters.    
    const loginCheck = async (formData) => {
      console.log("PDM Login initiated");
      openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Authenticating..."}</p></div>,300000)
      let loginReply = await PDMLogin(instance, formData);
      console.log(loginReply);
      if (loginReply == formData['username'] || loginReply == accounts[0].username)
      {
        props.setPdmUsername(formData['username']);
        console.log("Login was successful");
        closeSuccessSnackbar();
      }
      else
      {
        sessionStorage.setItem("pdmUsername", "");
        sessionStorage.setItem("pdmPassword", "");
        if(loginReply==null || loginReply=="")
        {
          loginReply="Login failed : PDM unreachable.";
        }
        console.log("Login failed:", loginReply)
        openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{loginReply}</p></div>,300000)
      }
    }; 

    const encryptPassword = (password, secretKey) => {
      let skey = CryptoJS.enc.Utf8.parse(secretKey);
      let encryptedBytes = CryptoJS.AES.encrypt(password, skey, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
      let encryptedString = encryptedBytes.toString();
      return encryptedString;
    };
    
return (
  <Container component="main" maxWidth="xs">
    <CssBaseline />
    <Box style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh"}}>
      {/* <Avatar>
        <LockOutlinedIcon />
      </Avatar> */}
      <Typography component="h1" variant="h5">
        PDM Sign in
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          inputProps={{ style: { textTransform: "lowercase" } }}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        {/* <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label="Remember me"
        /> */}
        <Button
          style={{marginTop: "18px"}}
          type="submit"
          fullWidth
          variant="contained"
          className={clsx(globalClasses.blueButton)}
        >
          Sign In
        </Button>
        {/* <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid> */}
      </Box>
    </Box>
  </Container>
);
}
import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import * as XLSX from 'xlsx';
import UniqueID from 'uniqid';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import DeleteButton from '@material-ui/icons/Delete'
import { Box, Grid, IconButton, makeStyles, Popover, TextField, Tooltip, Typography } from '@material-ui/core';
import ReactTable from './TableWithColumnResizer'
import {GlobalUseStyles} from './GlobalUseStyles.js';
import { useSnackbar } from 'react-simple-snackbar';
import { errorSnackbarOptions, successSnackbarOptions, warningSnackbarOptions } from './SnackbarOptions';
import cloneDeep from 'lodash/cloneDeep';
import { forEach, initial } from 'lodash';
import { ErrorOutline } from '@material-ui/icons';
import { TableVirtuoso } from 'react-virtuoso';

// cellClass: "rdg-cell-editable"
const initialTitles = [
  { key: 'Characteristic', name: 'Characteristic', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "true" },
  { key: 'Type', name: 'Type', resizable: true, width: "30px", editable: true, frozen: true, defaultTitle: "true" },
  { key: 'Default value', name: 'Default', resizable: true, width: "5px", editable: true, frozen: true, defaultTitle: "true" },
];


function numToAlpha(num) {

  var alpha = '';

  for (; num >= 0; num = parseInt(num / 26, 10) - 1) {
    alpha = String.fromCharCode(num % 26 + 0x41) + alpha;
  }

  return alpha;
}

const offset = 144;
let counter = 1;

const GetRunningNum = (orderName) => {
  let splitName = orderName.split("-");
  if (splitName.length >= 2) {   
      return splitName[splitName.length - 2].slice(-4);
  }
  else {
      return orderName.slice(-4);
  }
};

const GetRunningNumAndPos = (orderName) => {
  let splitName = orderName.split("_");
  if (splitName.length >= 2) {   
      return splitName[splitName.length - 1];
  }
  else {
      let splitName2 = orderName.split("-");
      if (splitName2.length >= 2)
      {
        return splitName2[splitName2.length-2].slice(-4) + "-" + splitName2[splitName2.length-1];
      }
      else
      {
      return orderName.slice(-4);
  }
  }
};    

function SaveExcel(defChars, name, tableOrders, material, revision) {
  let characteristics = cloneDeep(defChars);
  let tempChars = [];

  console.log("characteristics: ", characteristics);
  console.log("name: ", name);
  let headerRow = ["CHARACTERISTIC", "TYPE", "DEFAULT"];

  if (tableOrders != null && tableOrders.length > 0) {
     tableOrders.forEach((orderElement) => {
    const dynamicPart = GetRunningNumAndPos(orderElement.name);  
    headerRow.push(dynamicPart);  
  });
  }

  tempChars.push(headerRow);
  let descriptionRow = ["Description","",""];
  
  if (tableOrders != null && tableOrders.length > 0) {
    tableOrders.forEach((orderElement) => {
      descriptionRow.push(orderElement.description || "");  
    });
  }

  tempChars.push(descriptionRow);

  characteristics.forEach((element) => {
    let characteristicRow = [
      element["name"],     
      element["dataType"], 
      element["value"]     
    ];
    tableOrders.forEach((orderElement) => {
      let orderChars = orderElement["characteristics"];
      if (orderChars[element["name"]] != null) {
        characteristicRow.push(orderChars[element["name"]]);  
      } else {
        characteristicRow.push("");  
      }
    });

    tempChars.push(characteristicRow);
  });
  var ws = XLSX.utils.aoa_to_sheet(tempChars);
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  XLSX.writeFile(wb, name + "_" + material + "_" + revision + ".xlsx");
}

const useStyles = makeStyles((theme) => ({
  dataGrid: {
    width: "100%"
  },
  dataRow: {
    textAlign: "left"
  },
  cell: {

  },
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&&:after": {
      borderBottom: "none"
    },
  },
  th: {
    position: "sticky",
    zIndex: 1,
    top: "0px",
    backgroundColor: "#FAFAFA",
    borderTop: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    verticalAlign: "top",
    textAlign: "left",
    borderLeft: "1px solid #ddd",
    fontSize: "20px",
    "&:last-child": {
      borderRight: "1px solid #ddd",
      // width: "1px",
      // whiteSpace: "nowrap",
    },
    "&:hover": {
      "& $deleteButton": {
        opacity: "1",
      }
    }
  },
  td: {
    // border: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    //borderRight: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    //backgroundColor: "#fAfAfA",
    whiteSpace: "nowrap",
    width: "auto",
    padding: "4px",
    textAlign: "left",
    "&:last-child": {
      borderRight: "1px solid #ddd",
      // width: "1px",
      // whiteSpace: "nowrap",
    },
    "&:hover": {
      backgroundColor: "#eee",
    }
  },
  tr: {
    verticalAlign: "center",
    textAlign: "center",
    // whiteSpace: "nowrap",
    // width: "1%",
    "&:nth-child(even)": {
      backgroundColor: "#f2f2f2",
    },
    "&:hover": {
      backgroundColor: "#eee",
    }
  },
  headerInput: {
    display: "flex",
    margin: "0px",
    textAlign: "left",
    alignContent: "left",
    backgroundColor: "#fAfAfA",
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderLeft: "1px solid #ddd",
    fontSize: "18px",
    padding: "5px",
    width: "100%",
    height: "auto",
    "&::placeholder": {
      color: "black",
      opacity: 1,
    }
  },
  thickRightBorder: {
    borderRight: "3px solid #ddd",
    paddingRight: "0px"
  },
  removeLeftBorder: {
    borderLeft: "3px solid #ddd",
    width: "112px",
    minWidth: "112px",
    maxWidth: "112px"
  },
  inputHeaderSmall: {
    alignContent: "left",
    width: "70%",
  },
  inputHeaderBig: {
    alignContent: "left",
    width: "100%"
  },
  headerBox: {
    maxHeight: "40px",
    display: "flex",
    textAlign: "center",
    maxWidth: "150px"
  },
  headerText: {
    //width: "100%",
    margin: "0px auto 0px auto",
    textAlign: "left",
    fontSize: "0.80em",
    //float: "left",
    paddingLeft: "5px",
    paddingRight: "5px",
    backgroundColor: "inherit",
    verticalAlign: "middle",
    // display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "default",
    maxWidth: "150px"
  },
  headerOrderText: {
    margin: "0px auto 0px auto",
    textAlign: "left",
    fontSize: "0.80em",
    //float: "left",
    paddingLeft: "5px",
    paddingRight: "5px",
    backgroundColor: "inherit",
    verticalAlign: "middle",
    // display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "default",
    maxWidth: "140px"
  },
  headerDelete: {
    // width: "30%",
    display: "inline-block",
    verticalAlign: "middle",
    //float: "right",
    padding: "0px",
    maxWidth: "24px"
  },
  deleteButton: {
    opacity: "0",
    // display: "block"
    // "&:hover": {
    //     opacity: "1",
    //   }
  },
  rowInput: {
    border: "0px",
    textAlign: "left",
    fontSize: "12px",
    outline: "none",
    width: "100%",
    display: "block",
    boxSizing: "border-box",
  },
  allInputs: {
    border: "0px",
    textAlign: "left",
    outline: "none",
    width: "250px",
    display: "inline-block",
    boxSizing: "border-box",
    backgroundColor: "inherit",
  },
  addMargin: {
    marginTop: "10px"
  },
  removeOutline: {
    outline: "none",
    border: "0px solid black",
    "&:focus": {
      outline: "none",
    }
  },
  widenOnHover: {
    "&:hover": {
      width: "200px",
    }
  },
  leftColumns: {
    zIndex: 2,
    position: "sticky",
    backgroundColor: "#f0f0f0"
  },
  leftRows: {
    position: "sticky",
    backgroundColor: "#f0f0f0"
  },
  disabledInput: {
    color: "black"
  },
  orderHeaders: {
    width: "112px",
    minWidth: "112px",
    maxWidth: "112px"
  },
  firstOrderHeaderBox: {
    width: "112px",
    minWidth: "112px",
    maxWidth: "112px"
  },
  inputStyle: {
    height: "8px",
  },
  textField: {
    minHeight: "16px",
    maxHeight: "22px",
    marginRight: "0px",
    minWidth: "0px",
    width: "0",
    flex: "0 0 30px"
  }
}));

export const CustomDataSheet = (props) => {
//export default function CustomDataSheet(props) {
  const [grid, setGrid] = useState(props.grid);
  const [headers, setHeaders] = useState(null);
  const [excelData, SetExcelData] = useState(props.initialExcel);
  // const [counter, setCounter] = useState(5);
  const [currentHeaderIndex, setCurrentHeaderIndex] = useState(-1);
  const [reRender, setReRender] = useState(true);
  const [deleteIsShown, setDeleteIsShown] = useState(true);
  const [saveExcel, setSaveExcel] = useState(false);
  const [loadExcel, setLoadExcel] = useState(false);
  const [saveCase, setSaveCase] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const firstRender = useRef(true);
  const [batch, setBatch] = useState(props.selectedBatch == null ? null : props.selectedBatch);
  //const [selectedBatchId, setSelectedBatchId] = useState(props.selectedBatchID == "" ? "" : props.selectedBatchID);
  const [orders, setOrders] = useState(props.tableOrders == null ? null : props.tableOrders);
  const [openWarningSnackbar, closeWarningSnackbar] = useSnackbar(warningSnackbarOptions());
  const classes = useStyles();
  const globalClasses = GlobalUseStyles();
  const { tableData, populateTableWithApiResponse, apiResponse } = props;
  let currentHeaderFocus = null;
  let name = props.excelPath;
  let dataFetched = false;
  let ordersLoaded = false;
  let result = [];

  const ParseExcel = function (file, chars) {
    var reader = new FileReader();

    reader.onload = function (e) {
      var data = e.target.result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });


      var json_obj = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {header:"A"});
      //var json_obj = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
            
      console.log("Excel converted to json: ", json_obj);
    
      // CHARACTERISTIC CHECK
      let errorFound = "";
      if (json_obj.length != chars.length) {
        // wrong amount of characteristics
        errorFound = "Wrong amount of characteristics";
      }
      else {
        console.log();
        for (let index = 0; index < chars.length; index++) {
          const element = chars[index];
          const excelElement = json_obj[index];
          if (!(Object.values(excelElement).includes(element["name"]))) {
            // not found
            errorFound = "Characteristics from excel don't match default characteristics";
          }
        }
      }
      if (errorFound != "") {
        console.log("ERROR: ", errorFound);
      }
      PopulateTable(json_obj, chars)
    };

    reader.onerror = function (ex) {
      console.log(ex);
    };

    reader.readAsBinaryString(file);
  };



  const LoadExcel = function (chars, excelInputFile) {
    //console.log("LOAD FILE: ", excelInputFile);
    ParseExcel(excelInputFile, chars);
    // let workbook = XLSX.read(excelInputFile, {type: "buffer"});
    // let wsname = workbook.SheetNames[0];
    // let ws = workbook.Sheets[wsname];
    // let data = XLSX.utils.sheet_to_json(ws);
    // console.log("DATA: ", data);
  }

  const PopulateTable = (json_obj, chars) => {
  let emptyTitles = [];
  initialTitles.forEach(element => {
    emptyTitles.push(element);
  });
  
  let headerRow = -1;
  for (let h = 0; h < 4; h++)
    {
     if (json_obj[h] != null && json_obj[h].A != null && json_obj[h].A.toUpperCase() == "CHARACTERISTIC")
      {
      headerRow = h;
    }
  }
    
  if (headerRow == -1)
    {
    console.log("Error: Could not find characteristic header row in excel");
    return;
  }
  
  let headerObj = json_obj[headerRow];
  let startCol = -1;
  if (headerObj.B == "TYPE")
    startCol = 3;
  else if (headerObj.B == "Value / String")
    startCol = 2;
        
  if (startCol == -1)
    {
    console.log("Error: Could not determine starting column in excel");
    return;
  }
  console.log("headerRow: ", headerRow);
  console.log("headerObj: ", headerObj);
  console.log("startCol: ", startCol);
  console.log("headerObj.length: ", Object.keys(headerObj).length);

  if (Object.keys(headerObj).length - startCol > 50)
  {
    openWarningSnackbar(<div style={{whiteSpace: "pre-line"}}><ErrorOutline style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"50 test order limit reached\nCreate a new batch for the rest of the orders"}</p></div>,300000)
    return;
  }

  let newTitles = [];
  let newOrders = [];
  let counter = 0;

  let defChars = props.newBatch ? props.defChars : props.selectedBatch["defaultCharacteristics"];
  let descriptionRow = json_obj.find(row => row.A === "Description");

  if (!descriptionRow) {
    console.log("Error: Description row not found in the Excel data.");
    return;
  }

  let characteristicRow = json_obj.find(row => row.A === "CHARACTERISTIC");
  if (!characteristicRow) {
    console.log("Error: CHARACTERISTIC row not found in the Excel data.");
    return;
  }

  for (let h = startCol; h < Object.keys(headerObj).length; h++) {
    let newCharacteristics = {}
    let newKey = "010";
    let posNum = "010";
    for (let i = headerRow + 1; i < Object.entries(json_obj).length; i++) {
      let rowObj = json_obj[i];
      let charaname = rowObj.A;
      const charObj = defChars.find(obj => obj.name === charaname);
      let charatype = rowObj.B;
      let valueCol = numToAlpha(counter+3);
      let charavalue = rowObj[valueCol];
      if (charatype != undefined)
        {
        if (charatype.toLowerCase() == "integer")
          {
          if (valueCol != undefined)
            {
            const converted = parseInt(charavalue, 10);
            if (!isNaN(converted))
              {
              charavalue = converted;
            }
          }
        }
        else if(charatype.toLowerCase() == "v")
          {
          if (charObj != undefined)
            {
            if (charObj["name"] == charaname)
              {
              if (charObj["dataType"] == "integer")
                {
                const converted = parseInt(charavalue, 10);
                if (!isNaN(converted))
                  {
                  charavalue = converted;
                }
              }
            }
          }
        }
      }
      else if (charObj != undefined)
        {
        if (charObj["name"] == charaname)
          {
          if (charObj["dataType"] == "integer")
            {
            const converted = parseInt(charavalue, 10);
            if (!isNaN(converted))
              {
              charavalue = converted;
            }
          }
        }
      }
      newCharacteristics[charaname] = charavalue;
      let headerObj = json_obj[headerRow];
      newKey = headerObj[valueCol];
      let splitKey = newKey.split("-");
      if (splitKey.length > 1) {   
        posNum = splitKey[splitKey.length - 1];
      }
      // console.log("newKey; ", newKey);
      // console.log("rowObj; ", rowObj);
      // console.log("charaname; ", charaname);
      // console.log("valueCol; ", valueCol);
      // console.log("charavalue; ", charavalue);       
    }
    console.log(newCharacteristics);

    // Loop headers and create titles 

    counter += 1;
    let newName = '';
    let numbers = [];

    if (counter <= 9) {
      newName = '000' + counter.toString();
    }
    else if (counter > 9 && counter <= 99) {
      newName = '00' + counter.toString();
    }
    else if (counter > 99 && counter <= 999) {
      newName = '0' + counter.toString();
    }
    else if (counter > 999) {
      newName = counter.toString();
    }
// newName = props.selectedBatchID + "_" + newName + "-010";
      // let addToTitles = { key: newName, name: newName.slice(-8), resizable: true, width: "3%", editable: true, frozen: false };
      
      // Object.keys(json_obj[0]).forEach((element) => {
      //   console.log("KEY: ", element);
      //   console.log("newName: ", newName);
      //   if (element.startsWith(newName)) {
      //     newName = element;
      //     newKey = element;
      //   }
      // });

    posNum = "010";
    let titlesName = counter.toString() + "-" + posNum; 
    newName = props.selectedBatchID + newName + "-" + posNum;

     // props.titles.push(addToTitles);
      
      // setCounter(counter + 1);

      // newCharacteristics = {}
      // json_obj.forEach(element => {
      //   console.log("ELEM: ", element);
      //   newCharacteristics[element["CHARACTERISTIC"]] = element[newKey];
      // });

    let description = "";
    let formattedCounter = counter.toString().padStart(4, '0');
 let newKeyToMatch = formattedCounter + "-" + posNum;
    for (let col in characteristicRow) {
      if (col !== 'A' && characteristicRow[col] !== '' && descriptionRow[col] !== '') {
        if (characteristicRow[col] === newKeyToMatch){   
          description = descriptionRow[col];  
          props.setOrderDescription(description);
          break;
        }
      }
    }

    console.log("Description found for", newName, ":", description);
    
    let addToTitles = { key: newName, name: titlesName, description: description, resizable: true, width: "3%", editable: true, frozen: false, selected: true };
    newTitles.push(addToTitles);
    console.log("New order added to table: ", newName);

    let newOrder = {
      "batchID": props.selectedBatchID,
      "name": newName,
      "description": description,  
      "status": "Not run",
      "message": null,
      "resultFiles": [],
      "characteristics": newCharacteristics
    }
    newOrders.push(newOrder);
  }

//console.log("NEWORDERS: ", newOrders);
  setOrders([...newOrders]);
  props.setTableOrders([...newOrders]);

    newTitles.forEach(element => {
      emptyTitles.push(element);
    });
    props.setTitles([...emptyTitles]);
    props.setToggleAll(true);
    props.setCaseSelect(newTitles)

}

  const handleCounterDecrement = () => {
    // setCounter(counter - 1);
  };

useEffect(() => {
    if (props.apiResponse) {
    const newResponse = { ...props.apiResponse };
        console.log("New response:", newResponse);
        handleAddOrders(newResponse);
    }
  }, [props.apiResponse]);  // Runs when apiResponse changes



const handleAddOrders = (responseData) => {
  console.log("Received response data:", responseData);

  let newOrders = [...(orders || [])];  
  let newTitles = [];
  let numbers = [];
  newOrders.forEach(element => {
        let splitName = element["name"].split("_");
        let last4 = "";

      
        if (splitName.length > 1) {
            last4 = splitName[splitName.length - 1].slice(0, 4);
        } else {
            let splitName2 = element["name"].split("-");
            if (splitName2.length >= 2) {
                last4 = splitName2[splitName2.length - 2].slice(-4);  
            } else {
                last4 = element["name"].slice(-4);  
            }
        }

      
        let orderNumber = parseInt(last4, 10);
        if (!isNaN(orderNumber)) {
            numbers.push(orderNumber);
        }
    });

  
  numbers.sort((a, b) => a - b);

  let counter = 1;  
  let missingNumbers = [];

  
  if (numbers.length > 0) {
      let lastElement = 0;
      for (let i = 0; i < numbers.length; i++) {
          while (numbers[i] !== lastElement + 1) {
              missingNumbers.push(lastElement + 1);  
              lastElement++;
          }
          lastElement = numbers[i];  
      }
  }
  
 console.log("Missing order numbers:", missingNumbers);

  let posNum = "010";  
  let uploadedOrderNumbers = [];

  
  responseData.items.forEach((item, index) => {
      const attributes = item.attributes;
      const parameters = item.parameters;
      let identifier = item.identifier;

      let newCharacteristics = {};

       if (attributes) {
          Object.keys(attributes).forEach(attributeKey => {
              newCharacteristics[attributeKey] = attributes[attributeKey];
          });
      }

      if (parameters) {
          Object.keys(parameters).forEach(parameterKey => {
              newCharacteristics[parameterKey] = parameters[parameterKey];
          });
      }

      console.log("newCharacteristics:", newCharacteristics);

      let newOrderNumber;

     
      if (newOrders.length === 0) {
        numbers.push(1);  
        newOrderNumber = numbers[numbers.length - 1];
      } else {
         if (missingNumbers.length > 0) {
                newOrderNumber = missingNumbers.shift();
            } else {
                newOrderNumber = numbers[numbers.length - 1] + 1;
                numbers.push(newOrderNumber);  
            }
          }

      
      let newName = newOrderNumber.toString().padStart(4, '0');  
      
      
      let fullName = `${props.selectedBatchID}${newName}-${posNum}`;
      console.log("Full name generated for new order:", fullName);

      
      let newOrder = {
          "batchID": props.selectedBatchID,
          "name": fullName,
          "description": identifier,
          "status": "Not run",
          "message": null,
          "resultFiles": [],
          "characteristics": newCharacteristics
      };

      newOrders.push(newOrder);  
      
      let newTitle = {
          description: identifier,
          key: fullName,
          name: newName + "-010",
          resizable: true,
          width: "3%",
          editable: true,
          frozen: false
      };

      newTitles.push(newTitle);  

     
      uploadedOrderNumbers.push(newOrderNumber);
  });

  
  setOrders([...newOrders]);
  props.setTableOrders([...newOrders]);
  props.setTitles([...props.titles, ...newTitles]);
  props.setToggleAll(true);
  props.setCaseSelect(newTitles);

  console.log("Uploaded order numbers:", uploadedOrderNumbers);
};



  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const rows = grid.slice();
    //console.log(rows);
    for (let i = fromRow; i <= toRow; i++) {
      rows[i] = { ...rows[i], ...updated };
    }
    return { rows };
  };

  const deleteOnClick = (key, index) => {
    // setCounter(counter - 1);
    let objs = props.titles.slice();
    objs = objs.filter(obj => obj.name !== key.name);
    props.setTitles(objs);
    // console.log("TITLES: ", props.titles);
    // console.log("KEY: ",key);
    // console.log("ORDERS: ",orders);
    // console.log("BATCH: ",batch);
    let newOrders = orders;
    let tmpCaseSelect = [...props.caseSelect];
    tmpCaseSelect = tmpCaseSelect.filter(ord => ord["key"] !== key["key"]);
    newOrders = newOrders.filter(ord => ord["name"] !== key["key"]);
    props.setCaseSelect(tmpCaseSelect);
    setOrders([...newOrders]);
    props.setTableOrders([...newOrders]);

    // SetExcelData(excelData.filter(function( obj ) {
    //   return obj.key !== key.name;
    // }));

    // excelData.forEach(element => {
    //   // element[key.key] = undefined;

    //   delete element[key.key];


    //   // Object.keys(element).forEach(function(k){
    //   //   // console.log("EXCELDATAKEY");
    //   //   // console.log(k);
    //   //   // console.log("KEY");
    //   //   // console.log(key.key);
    //   //   if (k === key.key) {

    //   //     delete element[k];
    //   //     console.log("FOUND");
    //   //   }
    //   //   else {
    //   //     if(!newExcelData.includes(element))
    //   //     {
    //   //       newExcelData.push(element);
    //   //     }
    //   //   }
    //   // });
    // });
    // // handleCounterDecrement();
    // counter -= 1;
    setReRender(!reRender);
  }

  const handleSetDeleteIsShown = (e, newDelIshwon, currentIndex) => {
    currentHeaderFocus = e;
    setCurrentHeaderIndex(currentIndex);
    if (newDelIshwon) {
      setDeleteIsShown(true);
    }
    else {

      setDeleteIsShown(false);
    }
  }

  const handleOrderTitleClick = (event, index, key) => {
    if (index < 3)
      return;
    
    let isOpen = Boolean(event.currentTarget);
    props.setPopoverAnchorEl(event.currentTarget);
    props.setPopoverOpen(isOpen);
    props.setPopoverID(isOpen ? 'simple-popover' : undefined);
    let ord = props.tableOrders.find((element) => {
      return element["name"] === key["key"];
    });
    props.setOrderDescription(ord["description"]);
    props.setSelectedOrder(ord);
    //props.setOrderDescription();
    setReRender(!reRender);
  };

  const handleRowInputChange = (e, currentIndex, key, firstRow, dataType) => {
    setCurrentHeaderIndex(currentIndex);

    console.log("key: ", key);
    console.log("firstRow: ", firstRow);
    console.log("props.tableOrders: ", props.tableOrders);
    let ord = props.tableOrders.find((element) => {
      console.log("element: ", element);
      return element["name"] === key;
    });
    console.log("ord: ", ord);
    var characteristics = ord["characteristics"];
    var resultVal = e.target.value;
    if (dataType.toLowerCase() == "integer")
    {
      const converted = parseInt(resultVal, 10);
      if (!isNaN(converted))
      {
        resultVal = converted;
        e.target.value = converted;
      }
    }
    characteristics[firstRow] = e.target.value;
    
    // if (props.defChars) 
    // {
    //   let theChar = props.defChars.find((element2) => {
    //     return element2["name"] === firstRow;
    //   });
    //   theChar[key] = e.target.value;
    // }

    //props.savedExcelData(excelData);
  }

  const handleHeaderCheckboxChange = (e, key) => {
    let tmpCaseSelect = [...props.caseSelect];
    tmpCaseSelect = tmpCaseSelect.filter((obj) => obj.key != "All");
    let removing = false;
    if (key.selected == false)
    {
      if (!props.caseSelect.some(obj => obj.key === key.key))
      {
        tmpCaseSelect.push(key);
      }
    }
    else
    {
      if (props.caseSelect.some(obj => obj.key === key.key))
      {
        tmpCaseSelect = tmpCaseSelect.filter((obj) => obj.key != key.key);
        removing = true;
      }
    }
    if (props.tableOrders.length == tmpCaseSelect.length)
    {
      //All orders
      props.setToggleAll(true);
    }
    else
    {
      if (removing == true)
      {
        props.setToggleAll(false);
      }
    }
    props.setCaseSelect(tmpCaseSelect);
    key.selected = !key.selected;
    setReRender(!reRender);
  }

  const handleHeaderCheckboxAll = (e) => {
    var chk=document.getElementsByName("chk");
    var count=0;
    for(let i=0;i<chk.length;i++) {
    // @ts-ignore
    if(chk[i].checked) {
    count++;
    }}
    // @ts-ignore
    const allOrdersSelected = props.tableOrders.length === parseInt(count);
    props.setToggleAll(allOrdersSelected);
  }


  const setDataCheckbox = (e, key) => {
    let tmpCaseSelect = [...props.caseSelect];
    tmpCaseSelect = tmpCaseSelect.filter((obj) => obj.key != "All");
    if (e.target.checked)
    {
      if (!props.caseSelect.some(obj => obj.key === key.key))
      {
        tmpCaseSelect.push(key);
      }
    }
    else
    {
      if (props.caseSelect.some(obj => obj.key === key.key))
      {
        tmpCaseSelect = tmpCaseSelect.filter((obj) => obj.key != key.key);
      }
    }
    props.setCaseSelect(tmpCaseSelect);
  }

  // const getCellActions = (column, row) => {
  //   const cellActions = [
  //     {
  //       icon: <DeleteButton />,
  //       callback: () => {
  //         const rows = [...grid];
  //         rows.splice(row.index, 1); //
  //         setGrid(rows);
  //       }
  //     }
  //   ];
  //   return column.key === "Type" ? cellActions : null;
  // };
  const GetOrderName = (orderName) => {
    if (orderName.substr(0, orderName.lastIndexOf("_")).trim() == "")
    {
      return orderName.substr(0, orderName.lastIndexOf("-"));
    }
    else
    {
      return orderName.substr(0, orderName.lastIndexOf("_"));
    }
  };

  const getKeys = (d) => {
    // console.log(d);
    if (d == null) { return null; }
    if (typeof (d[0]) == "undefined") { return null; }
    // console.log("KEYS: ", d[0]);
    return Object.keys(d[0]);
  }

  const getHeader = (d) => {
    // var keys = getKeys(d);
    // return keys.map((key, index) => {
    //   return (
    //   <th key={UniqueID()}>
    //     <input className={clsx(classes.th, classes.underline)} placeholder={key.toUpperCase()}/>
    //     {/^\d+$/.test(keys[index]) ? <DeleteButton /> : null}
    //     {/* <TextField className={clsx(classes.th, classes.underline)}>{key.toUpperCase()}</TextField> */}
    //   </th>
    //   )

    //   // <th key={UniqueID()} className={classes.th}>{key.toUpperCase()}</th>
    // })

    // (/^\d+$/.test(key.name)) && classes.addMargin)
    var keys = getKeys(d);
    //console.log(keys);
    return d.map((key, index) => {
      let runChecked = false;
      if ("selected" in key)
      {
        runChecked = key["selected"];
      }
      let width = 0;
      let left = 0;
      let orderBoxWidth = 150;
      let pos = "";
      let runningNum = "";
      let initialRunningNum = "";
      if (index == 0) { width = 206; }
      else if (index == 1) { width = 50; left = offset; }
      else if (index == 2) { width = 84; left = offset + 70; }
      else { 
        // console.log("KeyName: " + key.name, index);
        // console.log("KeyKey: " + key.key, index);
        let splitName = key.key.split("-");
        let lastIndex = key.key.lastIndexOf("_");
        let lastIndex2 = key.key.lastIndexOf("-");
        // console.log("L1, L2: " + lastIndex + "|" + lastIndex2);
        // console.log("Dist: " + (lastIndex-lastIndex2));
        if (splitName.length > 1)
        {
          if (lastIndex-lastIndex2 == -5)
          {
            pos = splitName[splitName.length-1];
            runningNum = splitName[splitName.length-2].slice(-4);
          }
          else
          {
            let splitName2 = key.key.split("-");
            if (splitName2.length >= 2)
            {
              runningNum = splitName2[splitName2.length-2].slice(-4);
              pos = splitName2[splitName2.length-1];
            }
            else
            {
            runningNum = key.key.slice(-4).toUpperCase();
          }
        }
        }
        else 
        {
          runningNum = key.key.slice(-4).toUpperCase();
        }
        initialRunningNum = runningNum;
        runningNum = parseInt(runningNum, 10).toString();
        width = 64; // 64
      }
      let setLeftClass = false;
      if (index >= 0 && index < 3) { setLeftClass = true; }
      return (

        <th key={index}
          className={clsx(classes.th, setLeftClass && classes.leftColumns, (index == 2) && classes.thickRightBorder,
            (index == 3) && classes.removeLeftBorder, (index > 2) && classes.orderHeaders)}
          style={{ left: `${left}px` }}
        //onMouseOver={(e) => { handleSetDeleteIsShown(e, true, index) }}
        //onMouseOut={(e) => { handleSetDeleteIsShown(e, false, index) }}
        >

            <div className={clsx(classes.headerBox, (index == 3) && classes.firstOrderHeaderBox)}
            //style={{maxWidth: `${width+20}px`}}
            >
              {index > 2 ? 
              <input title="Execute order" type="checkbox" defaultChecked={props.newBatch?true:runChecked} name="chk" onClick={(e) => { handleHeaderCheckboxAll(e); }} onMouseOut={(e) => { setDataCheckbox(e, key); }} />
              :
              <></>  
              }
              <p
                style={{ cursor: "pointer" }}
                onClick={(e) => handleOrderTitleClick(e, index, key)}
                className={clsx((index > 2) && classes.headerOrderText, setLeftClass && classes.headerText)}
              //className={clsx(classes.headerInput, classes.removeOutline, classes.underline, classes.allInputs, (/^\d+$/.test(key.name)) && classes.inputHeaderSmall, !(/^\d+$/.test(key.name)) && classes.inputHeaderBig)} 
              >
                {index > 2 ?
                runningNum + (pos == "" ? "" : "-" + pos)
                :
                key.name.toUpperCase()
              }
              </p>

              {/* <input
        className={clsx(classes.headerInput, classes.removeOutline, classes.underline, classes.allInputs, (/^\d+$/.test(key.name)) && classes.inputHeaderSmall, !(/^\d+$/.test(key.name)) && classes.inputHeaderBig)} 
        defaultValue={key.name.toUpperCase()}
        style={{width: `${width}px`}}
        /> 
      && (currentHeaderIndex == index)*/}
              {(setLeftClass == false) && deleteIsShown ? <IconButton className={classes.headerDelete} onClick={() => deleteOnClick(key, index)}>
                <DeleteButton className={classes.deleteButton} />
              </IconButton> : null
              }
            </div>
            <p title={key["description"] == null ? "" : key["description"]} style={{ margin: "0", paddingLeft: "14px", display: "block", fontSize: "0.7rem", color: "rgba(0, 0, 0, 0.55)", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{key["description"] == null ? "" : key["description"]}</p>
          {/* <TextField className={clsx(classes.th, classes.underline)}>{key.toUpperCase()}</TextField> */}
        </th>
      )

      // <th key={UniqueID()} className={classes.th}>{key.toUpperCase()}</th>
    })
  }

  const RenderRowCells = (props) => {
    
    return props.keys.map((key, index) => {
      if (key != 'cardinality') {
        let width = 0;
        let left = 0;
        if (index == 0) { width = 206; }
        else if (index == 1) { width = 44; left = offset; }
        else if (index == 2) { width = 84; left = offset + 70; }
        else { width = 68; }
        let setLeftClass = false;
        let titleStr = props.data[key] == null ? "" : props.data[key] == " " ? " " : props.data[key];
        if (index > 0) {titleStr = "";}
        if (index >= 0 && index < 3) { setLeftClass = true; }
        if (key == 'value') { setLeftClass = true; }
        return <td key={index} className={clsx(classes.td, setLeftClass && classes.leftRows,
          (index == 2) && classes.thickRightBorder, (index == 3) && classes.removeLeftBorder)} style={{ left: `${left}px`, width: "70px", minWidth: "70px", maxWidth: "120px", padding: "0px", whiteSpace: "nowrap" }}>
            <input
              defaultValue={index < 3 ? props.data[key] : props.data[key]}
              className={clsx(classes.allInputs, classes.rowInput, classes.removeOutline, setLeftClass && classes.disabledInput)}
              style={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
              disabled={setLeftClass ? true : false}
              onChange={!setLeftClass ? (e) => { handleRowInputChange(e, props.rowIndex, key, props.data["name"], props.data["dataType"]) } : null}
              title={titleStr}
            />
        </td>
      }
    })
  }

  const getRowsData = (d) => {
    // console.log("CALLED!", props.tableOrders);
    // console.log("CALLED!", d);
    if (d == null) { return null; }
    //if (props.tableOrders == null) { return null;}
    // console.log(d);
    var items = d;
    //console.log(items);
    //var keys = getKeys(d);
    //console.log(items);
    // console.log(orders);

    //var vals = props.tableOrders.map(function(a) {return a["characteristics"];});

    //keys = keys.concat(orderKeys);
    // console.log("ORDERS", props.tableOrders);
    // console.log("ITEMS", items);
    // console.log("KEYS",keys);
    // console.log(keys);
    // console.log(vals);

    let tempChars = [];
    if (props.defChars) {
      for (var i = 0; i < props.defChars.length; i++) {
        let obj = props.defChars[i];
      //   let obj = {
      //     "dataType": props.defChars[i]["dataType"],
      //     "name": props.defChars[i]["name"],
      //     "value": props.defChars[i]["value"],
      // };
      //console.log("obj: ", obj);
      Object.keys(obj).forEach((e) => {
        if (e != "dataType" && e != "name" && e != "value")
          {
            delete obj[e];
          }
      });
        tempChars[i] = obj;
      }
      //console.log("props.defChars: ", props.defChars);
      //console.log("props.tableOrders: ", props.tableOrders);
      //console.log("tempChars: ", tempChars);
      if (props.tableOrders != null) {
        if (props.tableOrders.length > 0) {
          tempChars.forEach((element, index) => {
            props.tableOrders.forEach(element2 => {
              let orderName = element2["name"] //props.selectedBatchID + "-" + element2["name"];
              let tempCharObj = tempChars[index];
              let correctVal = element2["characteristics"][element["name"]];
              //tempCharObj[orderName] = tempChars[index].value;
              tempCharObj[orderName] = correctVal;
              tempChars[index] = tempCharObj;
            });
          });
        }
      }
      //console.log(tempChars);
    }
    else {
      let characteristics = cloneDeep(items["defaultCharacteristics"]);
      tempChars = cloneDeep(items["defaultCharacteristics"]);
      console.log('characteristics'+JSON.stringify(characteristics));
      console.log('tableOrders'+JSON.stringify(props.tableOrders));
      console.log(JSON.stringify(tempChars));
      if (props.tableOrders != null) {
        if (props.tableOrders.length > 0) {
          characteristics.forEach((element, index) => {
            console.log('index chk',index);
            console.log('element',element);
            props.tableOrders.forEach(element2 => {
              let orderChars = element2["characteristics"];
              console.log('orderChars',orderChars);
              console.log('element2',element2);
              // If order characteristics containing defaultcharacteristic 
              if(Object.prototype.hasOwnProperty.call(orderChars, element["name"])) {
                let tempCharObj = tempChars[index];
                // Set tempCharObj[char_name] value to order[char_name] value
                tempCharObj[element2["name"]] = orderChars[element["name"]];
                      //console.log(orderChars[element["name"]]);
                      //console.log(tempCharObj);
                tempChars[index] = tempCharObj;
                console.log('in tempChars',tempChars);
              }
            });
          });
        }
      }
    }
    //console.log("props.selectedBatch: ", props.selectedBatch);
    //console.log("FINAL TEMPCHARS: "+JSON.stringify(tempChars));
    var keys = getKeys(tempChars);
    if (keys) {
      return {"keys": keys, "tempChars": tempChars};
    }
  }

  // useEffect(() => {
  //   if (orders == null || ordersLoaded == true || props.ordersLoaded == true) {return;}
  //   let emptyTitles = [];
  //   initialTitles.forEach(element => {
  //     emptyTitles.push(element);
  //   });
  //   orders.forEach(element => {
  //     let addToTitles = { key: element["name"], name: element["name"].slice(-4), resizable: true, width: "5%", editable: true };
  //     emptyTitles.push(addToTitles);
  //   });
  //   props.setOrdersLoaded(true);
  //   //console.log("TITLES: ", emptyTitles);
  //   props.setTitles(emptyTitles);
  //   console.log(emptyTitles)
  //   ordersLoaded = true;
  // }, [orders]);

  useEffect(() => {
    if (props.tableOrders == null || ordersLoaded == true || props.ordersLoaded == true) { return; }
    let emptyTitles = [];
    initialTitles.forEach(element => {
      emptyTitles.push(element);
    });
    let allSelected = true;
    props.tableOrders.forEach(element => {
      let splitName = element["name"].split("_");
      let newName = "";
      if (splitName.length > 1)
      {
        newName = splitName[splitName.length-1];
      }
      else
      {
        let splitName2 = element["name"].split("-");
        if (splitName2.length >= 2)
        {
          newName = splitName2[splitName2.length-2].slice(-4) + "-" + splitName2[splitName2.length-1];
        }
        else
        {
        newName = element["name"].slice(-4);
      }
        
      }
      let initiallySelected = false;
      if (props.caseSelect.some( obj => obj["key"] === element["name"] ))
      {
        initiallySelected = true;
      } 
      else
      {
        allSelected = false;
      }
      let addToTitles = { key: element["name"], name: newName, description: element["description"], resizable: true, width: "5%", editable: true, selected: initiallySelected };
      emptyTitles.push(addToTitles);
    });
    //console.log("props.tableOrders: ", props.tableOrders);
    //console.log("emptyTitles: ", emptyTitles);
    if (allSelected == true)
    {
      props.setToggleAll(true);
    }
    props.setOrdersLoaded(true);
    props.setTitles(emptyTitles);
    ordersLoaded = true;
  }, [props.tableOrders]);

  useEffect(() => {
    setReRender(!reRender);
  }, [props.caseSelect]);

  useEffect(() => {
    setInitDone(props.initDone);
  }, [props.initDone]);

  useEffect(() => {
    if (excelData == [] || excelData == null) { return; }

  }, [props.saveCase]);

  useEffect(() => {
    let defChars = props.newBatch ? props.defChars : props.selectedBatch["defaultCharacteristics"];
    if (props.saveExcel != saveExcel) {
      SaveExcel(defChars, props.selectedBatchID, props.tableOrders, props.koneMaterial, props.koneMaterialRev);
      setSaveExcel(props.saveExcel);
    }
  }, [props.saveExcel]);

  useEffect(() => {
    if (firstRender.current == true) {
      firstRender.current = false;
      return;
    }
    let defChars = props.newBatch ? props.defChars : props.selectedBatch["defaultCharacteristics"];
    if (props.excelInputFile == null) { return; }
    LoadExcel(defChars, props.excelInputFile);
    setLoadExcel(props.loadExcel);
  }, [props.excelInputFile]);

  useEffect(() => {
    //console.log(props.tableOrders);
    if (initDone == false) { return; }
    let tempOrders = props.tableOrders;

    if (tempOrders != null)
    {
      if (props.orderAmount + tempOrders.length > 50)
      {
        openWarningSnackbar(<div style={{whiteSpace: "pre-line"}}><ErrorOutline style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"50 test order limit reached\nCreate a new batch for the rest of the orders"}</p></div>,300000)
        return;
      }
    }
    else
    {
      if (props.orderAmount > 50)
      {
        openWarningSnackbar(<div style={{whiteSpace: "pre-line"}}><ErrorOutline style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"50 test order limit reached\nCreate a new batch for the rest of the orders"}</p></div>,300000)
        return;
      }
    }

    let newTitles = [];
    for (let i = 0; i < props.orderAmount; i++) {

      let newName = '';
      let numbers = [];

      if (tempOrders != null) {
        tempOrders.forEach(element => {
          let splitName = [];
          splitName = element["name"].split("_");
          let last4 = "";
          console.log("splitName: ", splitName);
          if (splitName.length > 1) {
            last4 = splitName[splitName.length-1].slice(0, 4);
          }
          else
          {
            let splitName2 = element["name"].split("-");
            if (splitName2.length >= 2)
            {
              last4 = splitName2[splitName2.length-2].slice(-4);
            }
            else
            {
            last4 = element["name"].slice(-4);
          }
            
          }
          // let last4 = element["name"].slice(-4);
          numbers.push(parseInt(last4, 10));
        });
      }


      numbers.sort(function (a, b) {
        return a - b
      });


      let lastElement = 0;
      for (let index = 0; index < numbers.length; index++) {
        const element = numbers[index];
        counter = element;
        // console.log("index: ", index)
        // console.log("numLen: ", numbers.length)
        // console.log("ELEMENT: ", element)
        // console.log("LASTELEMENT: ", lastElement)
        if (element != lastElement + 1 && index > 0) {
          counter = lastElement + 1;
          index = numbers.length;
        }
        if (index == numbers.length - 1) {
          counter += 1;
        }
        lastElement = numbers[index];
      }


      if (numbers.length == 0) {
        counter = 1
      }

      if (counter <= 9) {
        newName = '000' + counter.toString();
      }
      else if (counter > 9 && counter <= 99) {
        newName = '00' + counter.toString();
      }
      else if (counter > 99 && counter <= 999) {
        newName = '0' + counter.toString();
      }
      else if (counter > 999) {
        newName = counter.toString();
      }
      console.log("newName: ", newName);
      console.log("counter2: ", counter);
      let newKey = props.selectedBatchID + newName + "-010";
      let addToTitles = { key: newKey, name: newName + "-010", resizable: true, width: "3%", editable: true, frozen: false, selected: true };
      newTitles.push(addToTitles);
      // props.titles.push(addToTitles);
      // setCounter(counter + 1);
      counter += 1;

      let newCharacteristics = {};
      if (props.newBatch) {
        console.log("props.defChars: ", props.defChars);
        for (var c = 0; c < props.defChars.length; c++) {
          let obj = props.defChars[c];
          let s_name = obj.name;
          let o_value = obj.value;
          if (o_value != null && obj.dataType == "list_item" && o_value.includes("[") && o_value.includes("]")) {
            o_value = o_value.replace("[","").replace("]","")
          }
          newCharacteristics[s_name] = o_value;
        }
      }
      else if (props.selectedBatch["defaultCharacteristics"]) {
        props.selectedBatch["defaultCharacteristics"].forEach(element => {
          if (element["dataType"] == "list_item") {
            let o_value = element["value"];
            if (o_value != null && typeof o_value === "string" &&  o_value.includes("[") && o_value.includes("]")) {
              element["value"] = o_value.replace("[","").replace("]","")
            }
          }
          newCharacteristics[element["name"]] = element["value"];
        });
      }

      let newOrder = {
        "batchID": props.selectedBatchID,
        "name": props.selectedBatchID + newName + "-010",
        "description": "",
        "status": "Not run",
        "message": null,
        "resultFiles": [],
        "characteristics": newCharacteristics
      }

      // console.log("newName: ", newName);
      console.log("selectedBatchId: ", props.selectedBatchID);
      // console.log("newCharacteristics:");
      // console.log(newCharacteristics);
      // console.log("newOrder:");
      // console.log(newOrder);

      let newOrders = [];
      if (tempOrders != null) {
        newOrders = [...tempOrders];
      }
      newOrders.push(newOrder);

      console.log("newOrders: ", newOrders);
      // console.log("props.defChars:", props.defChars);
      // let newDefchars = [];
      // let newDefChar = null;
      // props.defChars.forEach((e, index, theArray) => {
      //   let keys = Object.keys(e);
      //   console.log("e: ", e);
      //   console.log("KEYS: ", keys);
      //   newDefChar = {...e};
      //   newOrders.forEach((e2) => {
      //     newDefChar[e2["name"]] = e2["characteristics"][e["name"]];
      //   });
      //   console.log("newDefChar: ", newDefChar);
      //   console.log(" ");
      //   let clonedDefChar = cloneDeep(newDefChar);
      //   newDefchars.push(clonedDefChar);
      //   theArray[index] = clonedDefChar;
      // });
      // console.log("newDefchars: ", newDefchars);
      // props.setdefChars(newDefchars);

      // newOrders.forEach((e) => {
      //   props.defChars.forEach((e2) => {
      //     if (e2["name"] in e["characteristics"])
      //     {
      //       let key = e2["name"];
      //       let newVal = e["characteristics"][key];
      //       let ordername = e["name"];
      //       let newElem = {...e2};
      //       newElem[ordername] = newVal;
      //       console.log("key:", key);
      //       console.log("newVal:", newVal);
      //       console.log("ordername:", ordername);
      //       console.log("e2:", e2);
      //       console.log(" ");
      //       newDefchars.push(newElem);
      //     }
      //   });
      // });
      let tmpCaseSelect = [...props.caseSelect];
      if (!tmpCaseSelect.some(obj => obj.key === addToTitles.key))
      {
        tmpCaseSelect.push(addToTitles);
      }
      props.setCaseSelect(tmpCaseSelect);
      
      setOrders([...newOrders]);
      props.setTableOrders([...newOrders]);
      tempOrders = [...newOrders];
    }
    let tempTitles = [...props.titles];
    newTitles.forEach(element => {
      tempTitles.push(element);
    });
    props.setTitles([...tempTitles]);
    console.log("tempTitles: ", tempTitles);
  }, [props.addCase]);

  // useEffect(() => {
  //   titles.push(addToTitles);
  // }, [props.addHandler])

  useEffect(() => {
    if (firstRun == true)
    {
      setFirstRun(false);
      return;
    }
    let tglAll = props.toggleAll;
    let tmpCaseSelect = [...props.caseSelect];
    let tmpTitles = [...props.titles];
    tmpTitles.forEach(element => {
      let addCase = true;
      if (element["key"] == "Characteristic" || element["key"] == "Type" || element["key"] == "Default value")
      {
        addCase = false;
      }
      if (addCase == true)
      {
        if (tglAll == true)
        {
          element["selected"] = true;
          if (!tmpCaseSelect.some(obj => obj.key === element.key))
          {
            tmpCaseSelect.push(element);
          }
          if (!tmpCaseSelect.some(obj => obj["key"] === "All"))
          {
            let newObj = { key: 'All', name: 'All', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "false", description: "", selected: false }
            tmpCaseSelect.push(newObj);
          }
        }
        else
        {
          element["selected"] = false;
          if (tmpCaseSelect.some(obj => obj.key === element.key))
          {
            tmpCaseSelect = tmpCaseSelect.filter((obj) => obj.key != element.key);
          }
          tmpCaseSelect = tmpCaseSelect.filter((obj) => obj.key != "All");
        }
      }
        
      
    });
    props.setCaseSelect(tmpCaseSelect);
    props.setTitles(tmpTitles);
  }, [props.callToggleAll]);

  useEffect(() => {
// console.log("PROPS.TITLES: ", props.titles);
// console.log("props.selectedBatch: ", props.selectedBatch);
// console.log("props.defChars: ", props.defChars);
// console.log("props.newBatch: ", props.newBatch);
    result = [];
    let keysAndTempChars = props.newBatch ? getRowsData(props.defChars) : getRowsData(props.selectedBatch);
    let newTempChars = [];
    let newKeys = [];
    if (keysAndTempChars != null)
    {
        newTempChars = keysAndTempChars["tempChars"];
        newKeys = keysAndTempChars["keys"];
    }

      result.push(
        <TableVirtuoso
      style={{ height: "100%", width: "100%" }}
      data={newTempChars}
      fixedHeaderContent={() => (
        <tr>
          {getHeader(props.titles)}
        </tr>
      )}
      itemContent={(index) => {
        const row = newTempChars[index];
        return (
          <>
          <RenderRowCells data={row} keys={newKeys} />
          </>
        )
      }}
    />
      )

    setGrid(result);
    props.setGrid(result);
  }, [props.addCase, reRender, deleteIsShown, orders, props.titles, props.selectedBatch, props.defChars, props.selectedBatchID]);


  return <>{grid}</>;
}

import React, { useCallback, useState, useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import NewsBanner from './NewsBanner';
import { TestItems } from './TestItems'
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Hidden, IconButton, Input, InputLabel, ListItemText, Menu, MenuItem, Popover, Popper, Radio, Select, TableBody, TableHead, TableRow, TextField, Tooltip, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Refresh from '@material-ui/icons/Refresh';
import UniqueID from 'uniqid';
import BatchOptionCheckBoxes from './BatchOptionsCheckboxes';
import BatchOptionCheckbox from './BatchOptionCheckbox';
import { ArrowDownward, ArrowDropDown, ArrowRight, Save, PlayArrow, SaveAlt, Publish, Cancel, AssignmentTurnedIn, Cached, CheckBox, Edit, RemoveCircle, RemoveCircleOutline, Replay, Timer, GetApp } from '@material-ui/icons';
import { CustomDataSheet } from './DataSheet';
import { Table } from 'react-bootstrap';
import MuiTableCell from "@material-ui/core/TableCell";
import {GlobalUseStyles} from './GlobalUseStyles.js';
import { Autocomplete } from '@material-ui/lab';
import { fetchId, fetchMaterialCharacteristics, createBatch, createOrder, executeBatch, getBatchOrders, getMaterial, updateBatch, updateOrder, OptionsPackageDownload, OptionsPackageUpload, fetchPDMDescription, checkMaterialCharacteristics, fetchDataFromPDM } from './RestService';
import cloneDeep from 'lodash/cloneDeep';
import config from "../config";
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { useSnackbar } from 'react-simple-snackbar';
import { errorSnackbarOptions, successSnackbarOptions, warningSnackbarOptions } from './SnackbarOptions';
import { useMsal } from '@azure/msal-react';       
import { consoleMaterialList,consoleMaterials } from './TableBatches';
import {ConvertedTimeString, GetArrayEmpty, GetEmpty, GetStringEmpty} from './Common'
import { useHistory } from 'react-router-dom';
import ExecuteDialog from './ExecuteDialog';
import CachedIcon from '@material-ui/icons/Cached';
import CircularProgress from '@material-ui/core/CircularProgress';

const pdmRelationships = {"has part": "\"cws_configure_has_part\"", "all relationships": "\"cws_configure_all_relationships\"", "no relationships": "\"cws_configure_no_relationships\""};
var consoleRevisions = [];

const initialTitles = [
    { key: 'Characteristic', name: 'Characteristic', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Type', name: 'Type', resizable: true, width: "30px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Default value', name: 'Default', resizable: true, width: "5px", editable: true, frozen: true, defaultTitle: "true" },
  ];
    
let titles = [
    { key: 'Characteristic', name: 'Characteristic', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Type', name: 'Type', resizable: true, width: "30px", editable: true, frozen: true, defaultTitle: "true" },
    { key: 'Default value', name: 'Default', resizable: true, width: "5px", editable: true, frozen: true, defaultTitle: "true" },
  ];

const CustomRadio = withStyles({
    root: {
      color: "#0071B9",
      '&$checked': {
        color: "#0071B9",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);
      
function useForceUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue(value => value + 1);
}

function refreshRevisionList(selectedMaterial, consoleMaterialList)
{
  console.log("Refresh revision list.");          
  let revisions = [""];
  
  if(Array.isArray(consoleMaterialList)) {
    for (let i = 0; i < consoleMaterialList.length; i++)
    {
       let matrev = consoleMaterialList[i].split("/");   
       if (matrev[0] == selectedMaterial) {
        if (revisions.includes(matrev[1]) == false) {
          revisions.push(matrev[1]);
        }
      }
    }
  }
  revisions.splice(0, 1)
  revisions.sort();     
  revisions.reverse();
  consoleRevisions = revisions;  
  console.log(consoleRevisions);
  //consoleRevisions.push("A");
}   
 
const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);

const CustomAutocomplete = withStyles({
    inputRoot: {
      backgroundColor: "white",
      border: "solid",
      '&[class*="MuiInputBase-input"]': {
        height: "20px"
      }
    }
  })(Autocomplete);

const useStyles = makeStyles((theme) => ({
    root: {
        "& > .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root": {
          flexWrap: "nowrap",
          overflowX: "hidden"  // or "hidden"
        }
      },
    topButtonsDiv: {
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "row",
        marginTop: "20px",
        //marginLeft: theme.spacing(20)
    },
    topButton: {
        marginRight: "20px"
    },
    testInfoDiv: {
        display: "flex",
        flex: 1,
        height: "32px",
        justifyContent: "start",
        alignItems: "center",
        flexDirection: "row",
        marginTop: "20px",
        marginLeft: theme.spacing(4),
        paddingTop: "20px",
        paddingBottom: "30px"
    },
    testInfoItem: {
        display: "flex",
        justifyContent: "center",
        marginRight: "8px"
    },
    koneMaterialDiv: {
        display: "flex",
        flex: 1,
        height: "32px",
        justifyContent: "start",
        alignItems: "center",
        flexDirection: "row",
        paddingTop: "20px",
        marginTop: "20px",
        marginLeft: theme.spacing(4),
    },
    textField: {
        minHeight: "24px",
        maxHeight: "32px",
        marginRight: "32px"
    },
    testInfoDesc: {
        flex: 1
    },
    testInfoName: {
        flex: 1,
        marginRight: theme.spacing(2)
    },
    testInfoID: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(6),
        //flex: 1,
        width: "190px",
        backgroundColor: "#F0F0F0",
    },
    testInfoIDInput: {
        color: "#000"
    },
    generateIdButton: {
        //marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
        width: "44px",
        minWidth: "44px",
        maxWidth: "44px",
        height: "29px",
        maxHeight: "29px",
        minHeight: "29px"
    },
    supplierSpecificBtn: {
        //marginRight: theme.spacing(2),
        marginLeft: theme.spacing(1),
        width: "44px",
        minWidth: "44px",
        maxWidth: "44px",
        height: "29px",
        maxHeight: "29px",
        minHeight: "29px"
    },
    paper: {
        marginBottom: "0px",
        paddingBottom: "4px",
        flex: "1",
        justifyContent: "start",
        alignItems: "left",
    },
    paper2: {
        backgroundColor: "transparent",
    },
    testButton: {
        margin: theme.spacing(2),
        textTransform: 'none',
        alignSelf: "left",
        border: "none",
        '&:hover': {
            backgroundColor: "white"
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: 'white',
            borderColor: 'white',
        },
    },
    casesButtonsBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    testButtonDiv: {
        display: "flex",
        textTransform: 'none',
        alignItems: "left",
        alignSelf: "left",
        margin: "0 auto 0 0"
    },
    testOptionsItem: {
        textAlign: "left",
        marginLeft: theme.spacing(5),
        marginBottom: theme.spacing(2),
    },
    dataSheet: {
        display: 'flex',
        flexDirection: 'column',  // Organizes content vertically
        height: '40vh',  

        paddingLeft: 0,//(theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    testButtonOptions: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(0),
        marginBottom: theme.spacing(1),
        alignSelf: "flex-end"
    },
    testCasesButton: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(0),
        marginBottom: theme.spacing(1),
        alignSelf: "flex-end"
    },
    testCasesButtonsDiv: {
        minWidth: "10%",
        maxWidth: "100%",
        margin: "0 0 0 auto",
        display: "inline-flex",
        justifyContent: "flex-end",
        alignSelf: "flex-end"
    },
    testCasesButtonsDivBottom: {
        paddingTop: "16px",
        display: "flex",
        justifyContent: "flex-end",
        alignSelf: "flex-end"
    },
    addCaseButton: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    pdmOptionButton: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start"
    },
    addCaseIcon: {
        marginLeft: "0px"
    },
    executeDataCell: {
        padding: "0"
    },
    dialogPaper: {
        padding: "0px 0px",
        // minWidth: "500px",
        // maxWidth: "700px"
        minWidth: "500px",
        maxWidth: "1000px"
        
        //minHeight: "60vh",
        //maxHeight: "60vh"
    },
    topScrollPaper: {
        alignItems: 'flex-start',
      },
      topPaperScrollBody: {
        verticalAlign: 'top',
    },
    table: {
        border: "0px solid #F0F0F0",
        borderSpacing: "0px",
        tableLayout: "fixed",
        //borderCollapse: "collapse",
    },
    shown: {
        //margin: "auto",
        transform: "scaleY(1)",
        transformOrigin: "top",
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hidden: {
        height: "0px",
        transform: "scaleY(0)",
        transformOrigin: "bottom",
        transition: theme.transitions.create('transform', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    executeOptionsTable: {
        tableLayout: "auto",
        width: "100%"
    },
    inputStyle: {
        height: "8px",
    },
    orderPopup: {
        display: "flex",
        flexDirection: "column",
        flexFlow: "wrap",
        alignItems: "end"
    },
    orderPopupItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%"
    },
    popper: {
        maxWidth: "500px",
        minWidth: "50px",
     }
    // inputStyleSelect: {
    //     height: "18px",
    // }
}));

export default function BatchPage(props) {       
    const classes = useStyles();
    const globalClasses = GlobalUseStyles();
    const testName = props.testName.slice(0, -6);
    const testDescription = ""//props.testDescription;
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [testOptionsOpen, setTestOptionsOpen] = useState(false);
    const [testOrdersOpen, setTestOrdersOpen] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [reRender2, setReRender2] = useState(false);
    const [reRender3, setReRender3] = useState(false);
    const [reRender4, setReRender4] = useState(false);
    const [orderTitles, setOrderTitles] = useState(titles);
    const [exportExcel, setExportExcel] = useState(false);
    const [importExcel, setImportExcel] = useState(false);
    const [saveCase, setSaveCase] = useState(false);
    const [excelData, setExcelData] = useState(null);
    const [resetOrders, setResetOrders] = useState(false);
    const [orderAmount, setOrderAmount] = useState(1);
    const [checkedItems, setCheckedItemsChange] = useState(new Map());
    const [executeSettings, setExecuteSettings] = useState(new Map());
    const [caseSelect, setCaseSelect] = React.useState([]);
    const [customRelationships, setCustomRelationships] = React.useState(["has part"]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [orderSelectMenu, setOrderSelectMenu] = useState(null);
    const [orderSelectText, setOrderSelectText] = useState("");
    const [orderInputValue, setOrderInputValue] = useState("");
    const [orderAllSelected, setOrderAllSelected] = useState(false);
    const [firstRun, setFirstRun] = useState(true);
    const [ordersLoaded, setOrdersLoaded] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState(props.selectedBatch == null ? null : props.selectedBatch);
    const [selectedBatchOrders, setSelectedBatchOrders] = useState(props.selectedBatchOrders == null ? null : props.selectedBatchOrders);
    const [selectedBatchID, setSelectedBatchID] = useState(props.selectedBatch == null ? "" : props.selectedBatch["name"]);
    const [selectedPDMDescription, setSelectedPDMDescription] = useState(props.selectedBatch == null ? "" : props.selectedBatch["pdmDescription"]);
    const [batchDescription, setBatchDescription] = useState(props.selectedBatch == null ? "" : props.selectedBatch["description"]);
    const [koneMaterial, setKoneMaterial] = useState(props.selectedKoneMaterial == null ? null : props.selectedKoneMaterial);
    const [koneMaterialRev, setKoneMaterialRev] = useState(props.selectedKoneMaterialRev == null ? "" : props.selectedKoneMaterialRev);
    const [koneMaterialRevisions, setKoneMaterialRevisions] = useState(props.selectedKoneMaterial == null ? [] : consoleRevisions);
    const [lockFields, setLockFields] = useState(props.selectedKoneMaterial == null ? false : true);
    const [tableOrders, setTableOrders] = useState(props.selectedBatchOrders == null ? null : props.selectedBatchOrders);
    const [orderDescription, setOrderDescription] = useState("");
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [lockKoneMaterial, setLockKoneMaterial] = useState(false);
    const [openExecuteDialog, setOpenExecuteDialog] = useState(false);
    const [lockKoneMaterialRevision, setLockKoneMaterialRevision] = useState(false);
    const [grid, setGrid] = useState([]);
    const excelInput = useRef(null); 
    const optionFileInput = useRef(null); 
    const [excelInputFile, setExcelInputFile] = useState(null); 
    const [optionFile, setOptionFile] = useState(null); 
    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [toggleAll, setToggleAll] = useState(false);
    const [callToggleAll, setCallToggleAll] = useState(false);
    const [popoverID, setPopoverID] = useState(undefined);
    const [orderListOpen, setOrderListOpen] = useState(false);
    const [openSuccessSnackbar, closeSuccessSnackbar] = useSnackbar(successSnackbarOptions());
    const [openErrorSnackbar, closeErrorSnackbar] = useSnackbar(errorSnackbarOptions());
    const [openWarningSnackbar, closeWarningSnackbar] = useSnackbar(warningSnackbarOptions());
    const [skipModeValues, setSkipModeValues] = useState([]);
    const { instance, accounts } = useMsal();
    const xsVal = 12;
    const smVal = 4;
    const mdVal = 3;
    const [defChars, setdefChars] = useState(null);     
    const [lock, setLock] = useState(false);
    const [openLock, setOpenLock] = useState(false);
    const forceUpdate = useForceUpdate();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const fileInputRef = useRef(null);
    let _caseSelect = [];
    const [tableData, setTableData] = useState([]);
    const [apiResponse, setApiResponse] = useState(null);
    const [uploadMessage, setUploadMessage] = useState('');
  
    const populateTableWithApiResponse = (data) => {
    setApiResponse(data);
    setTableData(data.items);
    console.log('Populated table with data:', data);
    };                   
    const PopperMy = function (props) {
        return <Popper {...props} style={{maxWidth: "460px", minWidth: "160px"}} placement="bottom-start" />;
     };
    
//console.log(consoleMaterialList);
//console.log(consoleMaterials);
// console.log("MAT: ", koneMaterial);
// console.log("REV: ", koneMaterialRev);
// console.log("REVS: ", koneMaterialRevisions);

    const getPDMdata = () => {
    
      let message = "";
      let newDefChars = [];
      let fetchedId = "";      
      let statusMsg = "";       
      
      const CheckPDMFetchCharacteristics = async () => {                                                            
        console.log("Checking PDM characteristics for: " + koneMaterial + "/" + koneMaterialRev);
        statusMsg = await checkMaterialCharacteristics(instance,accounts[0].username,koneMaterial,koneMaterialRev);
      }
      CheckPDMFetchCharacteristics().catch((error)=>{message += error.message+"\n"}).then(() => {
        if (message != "")
        {
          openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not retrieve material data from PDM"}</p></div>,300000)
          console.log("Fetching material data from PDM failed: ", message);
        }
        else if (statusMsg != null && statusMsg.length != null && statusMsg == "NOT_FOUND")
        {
            openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{koneMaterial + "/" + koneMaterialRev + " do not exist in PDM, test orders can not be created"}</p></div>,300000)
        }
        else
        {
            const PDMFetchCharacteristics = async () => {                                                            
                console.log("Fetching PDM characteristics for: " + koneMaterial + "/" + koneMaterialRev);
                newDefChars = await fetchMaterialCharacteristics(instance,accounts[0].username,koneMaterial,koneMaterialRev);
            }        
            PDMFetchCharacteristics().catch((error)=>{message += error.message+"\n"}).then(() => {
                if (message != "")
                {
                    openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not retrieve material data from PDM"}</p></div>,300000)
                    console.log("Fetching material data from PDM failed: ", message);
                }
                else if (newDefChars != null && newDefChars.length != null && newDefChars.length == 0)
                {
                    openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not find material " + koneMaterial + "/" + koneMaterialRev + " from PDM"}</p></div>,300000)
                }
                else if (newDefChars != null && newDefChars.length != null && newDefChars.length > 0)
                {
                    setdefChars(newDefChars);  
                    const PDMFetchId = async () => {                        
                    console.log("Fetching PDM roll number for: " + accounts[0].username);
                    fetchedId = await fetchId(instance, accounts[0].username);     
                    fetchedId = fetchedId.replace("TESTCASE","CWS");
                    console.log(fetchedId.toString());
                    }
                    PDMFetchId().catch((error)=>{message += error.message+"\n"}).then(() => {
                    if (message != "")
                    {
                        openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not retrieve id from PDM"}</p></div>,300000)
                        console.log("Fetching id from PDM failed: ", message);
                    }
                    else
                    {
                        setSelectedBatchID(fetchedId);
                        setLockKoneMaterial(true);
                        setLockKoneMaterialRevision(true);
                        if (selectedBatch != null){
                        if (fetchedId != "") {
                            callGetBatchOrders();
                        }
                    }
                        setReRender3(!reRender3);
                    }
                    });
                }
            });
        }
    });
    
      
    }

    const getLatestPDMdata = () => {
    
        let message = "";
        let newDefChars = [];
        let fetchedId = "";             
                  
        const PDMLatestFetchCharacteristics = async () => {                                                            
          console.log("Fetching PDM characteristics for: " + koneMaterial + "/" + koneMaterialRev);
          newDefChars = await fetchMaterialCharacteristics(instance,accounts[0].username,koneMaterial,koneMaterialRev);
          
          console.log("defChars: " + JSON.stringify(selectedBatch["defaultCharacteristics"]));
          console.log("newDefChars: " + JSON.stringify(newDefChars));
          selectedBatch["defaultCharacteristics"] = newDefChars;
        }
        PDMLatestFetchCharacteristics().catch((error)=>{message += error.message+"\n"}).then(() => {
            if (message != "")
            {
                openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not retrieve material data from PDM"}</p></div>,300000)
                console.log("Fetching material data from PDM failed: ", message);
            }
            else if (newDefChars != null && newDefChars.length != null && newDefChars.length == 0)
            {
                openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not find material " + koneMaterial + "/" + koneMaterialRev + " from PDM"}</p></div>,300000)
            }
            else if (newDefChars != null && newDefChars.length != null && newDefChars.length > 0)
            {
                setdefChars(newDefChars);
                setLockKoneMaterial(true);
                setLockKoneMaterialRevision(true);
                if (selectedBatch != null){
                    if (fetchedId != "") {
                        callGetBatchOrders();
                    }
                }
                setReRender3(!reRender3);
            }
        });
    }

    const getPDMDescription = () => {
    
        let message = "";
        let pdmDescription = "";             
                  
        const PDMFetchDescription = async () => {                                                            
          pdmDescription = await fetchPDMDescription(instance,accounts[0].username,koneMaterial,koneMaterialRev);
          console.log(pdmDescription);
        }
        PDMFetchDescription().catch((error)=>{message += error.message+"\n"}).then(() => {
          if (message != "")
          {
            openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not retrieve PDM description from PDM"}</p></div>,300000)
            console.log("Fetching material data from PDM failed: ", message);
          }
          else if (pdmDescription != null && pdmDescription.length != null && pdmDescription == "NOT_FOUND")
          {
              openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{koneMaterial + "/" + koneMaterialRev + " do not exist in PDM, test orders can not be created"}</p></div>,300000)
          }
          else if (pdmDescription != null && pdmDescription.length != null && pdmDescription.length == 0)
          {
              openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Could not find PDM description for " + koneMaterial + "/" + koneMaterialRev + " from PDM"}</p></div>,300000)
          }
          else if (pdmDescription != null && pdmDescription.length != null && pdmDescription.length > 0)
          {
            setSelectedPDMDescription(pdmDescription);
          }
        });
      }
      
    const getCharacteristics = () => {
        
        // let tempBatch = null;
        // const batchOrders = async () => {
        //     const newMat = await getMaterial(instance, koneMaterial);
        //     var firstMat = newMat[0];
        //     if(!firstMat) {
        //       tempBatch = Object.create(null);
        //     }
        //     else {
        //       var tempBatches = firstMat["batches"];
        //       tempBatch = cloneDeep(tempBatches[0]);
        //     }
        // };
        // batchOrders().catch(console.error).then(() => {
        //     tempBatch["id"] = "";
        //     tempBatch["name"] = selectedBatchID;
        //     tempBatch["description"] = batchDescription;
        //     tempBatch["material"] = koneMaterial;
        //     tempBatch["materialRevision"] = koneMaterialRev;
        //     setSelectedBatch(tempBatch);
        //     //setReRender4(!reRender4);
        // });
    }

    const confirmUpdate = () => {
        openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Characteristics updated successfully"}</p></div>,300000);
    }

    const GetRunningNum = (orderName) => {
        let splitName = orderName.split("-");
        if (splitName.length >= 2) {   
            return splitName[splitName.length - 2].slice(-4);
        }
        else {
            return orderName.slice(-4);
        }
    };

    const GetRunningNumAndPos = (orderName) => {
        let splitName = orderName.split("_");
        if (splitName.length >= 2) {   
            return splitName[splitName.length - 1];
        }
        else {
            let splitName2 = orderName.split("-");
            if (splitName2.length >= 2)
            {
                return splitName2[splitName2.length-2].slice(-4) + "-" + splitName2[splitName2.length-1];
            }
            else
            {
            return orderName.slice(-4);
        }
        }
    };

    const callGetBatchOrders  = () => {
        if (ordersLoaded == true || selectedBatch["orders"] == null) {
            return;
        }
        let tempOrders = [];
        tempOrders.push(...selectedBatch["orders"]);
    //   const batchOrders = async () => {
    //       const newOrders = await getBatchOrders(instance, selectedBatch["id"]);
    //       if(Array.isArray(newOrders)) {
    //           newOrders.forEach(element => {
    //               tempOrders.push(element);
    //           });
    //       }
          
    //   };
    //   batchOrders().catch(console.error).then(()=>{
        tempOrders.sort((a,b) => parseInt(GetRunningNum(a["name"]), 10) - parseInt(GetRunningNum(b["name"]), 10));
        setTableOrders(tempOrders);
        props.setSelectedBatchOrders(tempOrders);
        
        let emptyTitles = [];
        initialTitles.forEach(element => {
            emptyTitles.push(element);
        });
        let allSelected = true;
        tempOrders.forEach(element => {
            let initiallySelected = false;
            if (GetEmpty(selectedBatch["executeOrders"]) == false)
            {
                if (GetEmpty(selectedBatch["orders"]) == false)
                {   
                    let regex = new RegExp("(?:" + selectedBatch["executeOrders"].join("|") + ")$");
   
                        if(GetRunningNumAndPos(element["name"]).match(regex))
                        {
                            initiallySelected = true;
                        }
                        else
                        {
                            allSelected = false;
                        }
                    }
            }
            let addToTitles = { key: element["name"], name: GetRunningNumAndPos(element["name"]), description: element["description"], resizable: true, width: "5%", editable: true, selected: initiallySelected };
            emptyTitles.push(addToTitles);
        });
        setToggleAll(allSelected);
        setOrdersLoaded(true);
        setOrderTitles(emptyTitles);

        setReRender2(!reRender2);
    //   });
    }

    const handleCloseSaveDialog = (event) => {
        setOpenSaveDialog(false);
    };

    const handleAnchorClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAnchorCloseClick = (event) => {
        setAnchorEl(null);
    };

    const getExcelData = (data) => {
        // console.log(data);
        // let test = [];
        // data.map((key, index) => (
        //     test.push(key)
        // ));
        // console.log(test);
        setExcelData(data);
    }
   
    const handleFileUpload = (e) => {
        const selectedFile = e.target.files[0];
        console.log("Selected File:", selectedFile); 
        
        if (selectedFile) {
            if (selectedFile.type === "text/plain") {
                handleFileRead(selectedFile);
                e.target.value = null;
            } else {
                openErrorSnackbar(
                    <div style={{ whiteSpace: "nowrap" }}>
                        <ErrorIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} />
                        <p style={{ display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap" }}>
                            {"Please select a valid .txt file."}
                        </p>
                    </div>,
                    300000 // Show for 300000 milliseconds (5 minutes)
                );
            }
        } else {
            
                    openErrorSnackbar(
                                        <div style={{ whiteSpace: "nowrap" }}>
                                            <ErrorIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} />
                                            <p style={{ display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap" }}>
                                                {"No file selected. Please select a .txt file."}
                                            </p>
                                        </div>,
                                        300000 // Show for 300000 milliseconds (5 minutes)
                                    );
                            }
                        };
    const handleFileRead = (file) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
            const fileContents = event.target.result;
            const lines = fileContents.split('\n').map(line => line.trim()).filter(line => line);

            const orders = [];
            const items = [];

            lines.forEach(line => {
                if (line.startsWith('order:')) {
                    orders.push(line);
                } else if (line.startsWith('item:')) {
                    items.push(line);
                }
            });

            console.log("Orders:", orders);
            console.log("Items:", items);
            setUploadMessage('Processing file...');
            setIsLoading(true);  

            await fetchData(orders, items);  
        };
        reader.readAsText(file);
    };

    const fetchData = async (orders, items) => {
        if (orders.length === 0 && items.length === 0) {
            console.log("No orders or items to send to the backend.");
            setIsLoading(false);
            setUploadMessage('');  
            openErrorSnackbar(
                <div style={{ whiteSpace: "nowrap" }}>
                    <ErrorIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} />
                    <p style={{ display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap" }}>
                        {"No orders or items found."}
                    </p>
                </div>,
                300000 // Show error for 5 minutes
            );
            return;
        }

        try {
            const responseData = await fetchDataFromPDM(instance, orders, items);  // Fetch data
            console.log("Fetched Data:", responseData);
            if (!responseData || !responseData.items || responseData.items.length === 0) {
                console.log("No valid orders were returned from the API.");
             openWarningSnackbar(
                    <div style={{ whiteSpace: "nowrap" }}>
                        <ErrorIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} />
                        <p style={{ display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap" }}>
                            {"No valid orders found in the file."}
                        </p>
                    </div>,
                    300000 
                );
                return; 
            }
            populateTableWithApiResponse(responseData);
            openSuccessSnackbar(
                <div style={{ whiteSpace: "nowrap" }}>
                    <SuccessIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} />
                    <p style={{ display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap" }}>
                        {"Order from File upload successful."}
                    </p>
                </div>,
                300000 // Show success for 5 minutes
            );
        } catch (error) {
            console.error("Error fetching data:", error);
            openErrorSnackbar(
                <div style={{ whiteSpace: "nowrap" }}>
                    <ErrorIcon style={{ display: "inline-block", verticalAlign: "middle", marginRight: "14px" }} />
                    <p style={{ display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap" }}>
                        {"Error occurred while fetching data."}
                    </p>
                </div>,
                300000 // Show error for 5 minutes
            );
        } finally {
            setIsLoading(false);  
            setUploadMessage('');  
        }
      };
    const handleKMChange = (e, value) => {
        var val = e.target.innerText;     
        console.log("val: " +  val);
        
        if (value == null){
            setKoneMaterialRevisions([]);
            setKoneMaterialRev("");
        }
        else{
            refreshRevisionList(val, consoleMaterialList);
            setKoneMaterialRevisions(consoleRevisions);
            setKoneMaterialRev(consoleRevisions[0]);
            
        }
        setKoneMaterial(value);
      };

      const convertToUppercase = (e) => {
        e.preventDefault();
        setKoneMaterial(e.target.value.toUpperCase());
      };

      const convertVersionToUppercase = (e) => {
        e.preventDefault();
        setKoneMaterialRev(e.target.value.toUpperCase());
      };

      const handleRevisionChange = (e, value) => {
        setKoneMaterialRev(e.target.value);
      };

    const handleCaseSelectChange = (e, value, r, details) => {
        caseSelect.length = 0;
        if (orderAllSelected == false && value.some(obj => obj.name === "All")) {
            setOrderAllSelected(true);
            setToggleAll(true);
            if (firstRun == true)
        {
                setCaseSelect([...value]);
        }
            else
            {
            orderTitles.forEach(element => {
                if ("selected" in element)
                {
                    element["selected"] = true;
                }
            });
            setCaseSelect([...orderTitles.filter((a) => (!("defaultTitle" in a) || a["defaultTitle"] == "false"))]);
        }
        }
        else if (orderAllSelected == true && (details != null && details["option"]["name"] === "All"))
        {
            setOrderAllSelected(false);
            setCaseSelect([]);
        }
        else {
            setOrderAllSelected(false);
            if (value.some((obj) => obj["key"] == "All"))
            {
                value = value.filter((obj2) => obj2["key"] != "All");
            }
            setCaseSelect([...value]);
        }
        setFirstRun(false);
        setReRender3(!reRender3);
      };

      

      const handleRelationshipSelectChange = (event, key) => {
        event.stopPropagation();
        var val = key.props["value"].toString();
        /*
        var indexOfValue = customRelationships.indexOf(val);
        //console.log(caseSelect);
        console.log(val + "|" + indexOfValue);
        if (val == "All") {
            if (customRelationships.indexOf("All") > -1) {
                customRelationships.length = 0;
                setCustomRelationships([...customRelationships]);
            } else {
                customRelationships.length = 0;
                setCustomRelationships([...Object.keys(pdmRelationships)]);
            }
        }
        else if (indexOfValue > -1) {
            customRelationships.splice(indexOfValue, 1);
            setCustomRelationships(customRelationships);
        }
        else {
            customRelationships.push(val);
            setCustomRelationships([...customRelationships]);
        }
        */
        customRelationships.length = 0;
        customRelationships.push(val);
        setCustomRelationships([...customRelationships]);
           
        console.log(customRelationships);
        setReRender3(!reRender3);
      };

    const handleSetTestCasesOpen = () => {
        //if (tableOrders == null && (props.newBatch == false || props.copyBatch == true))
        if (tableOrders == null && props.newBatch == false)
        {
            if (selectedBatch != null){
                callGetBatchOrders();
            }
        }
        setTestOrdersOpen(!testOrdersOpen);
    }
    const handleCheckedItemsChange = (newMap) => {
        setCheckedItemsChange(newMap);
        setReRender2(!reRender2);
    }
    const handleAddCase = (e) => {
        setReRender(!reRender);
    }
    const handleChange = (e) => {
        const item = e.target.name;
        const isChecked = e.target.checked;
        checkedItems.set(item, isChecked);
        handleCheckedItemsChange(checkedItems);
    }

    const handlePopoverClose = () => {
        setPopoverOpen(false);
        setPopoverID(undefined);
        setPopoverAnchorEl(null);
        //setReRender(!reRender);
      };

    function GetValueExists(name){
        for (var key of Array.from(executeSettings.keys())) {
            if (key === name)
            {
                return true;
            }
        }
        return false;
    }

    function GetValue(name){      
        for (var key of Array.from(executeSettings.keys())) {
            if (key === name)
            {
                return executeSettings.get(key);
            }
        }
        
        return null;
    }

    const handleExecuteSettingClick = (e, name, value) => {
        e.stopPropagation();
        executeSettings.set(name, value);
        setExecuteSettings(executeSettings);
        console.log(executeSettings);
        setReRender3(!reRender3);
    };

    const setCorrectMat = (mat) => {
        let splitMat = mat.split("/");
        return (splitMat[0] + "_" + splitMat[1] + "_Cases.xlsx")
    }

    const handleExportClick = () => {
        setExportExcel(!exportExcel);
    }

    const handleImportClick = () => {
        excelInput.current.click();
        //console.log("EIF: ", excelInput);
    }

    const handeExcelInputFileChange = (e) => {
        setExcelInputFile(e.target.files[0]);
        setImportExcel(!importExcel);
    }

    const handleOptionFileUpload = (e) => {
        var extension = e.target.files[0].name.split('.').pop();
        if (extension.toLowerCase() != "zip")
        {
            openErrorSnackbar(<div style={{whiteSpace: "pre-line"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"File type \"." + extension + "\" is not allowed"}</p></div>,300000)
            return;
        }

        let buffer;
        let errorMsg = "";
        const handleUploadEnd = () => {
            if (errorMsg != "")
            {
                openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{errorMsg}</p></div>,300000)
                return;
            }

            setOptionFile(e.target.files[0]);
            let optionsFileULData = null;
            let message = "";
            const optionsFileUL = async () => {
                optionsFileULData = await OptionsPackageUpload(instance, koneMaterial, koneMaterialRev, e.target.files[0].name, e.target.files[0],accounts[0].username);
            }
            optionsFileUL().catch((error)=>{message += error.message+"\n"}).then(() => {
            if (message != "")
            {
                openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{message}</p></div>,300000)
            }
            else
            {
                openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"CAD options file upload successful."}</p></div>,300000)
            }
            });
        }; 

        if (extension.toLowerCase() == "zip")
        {
            const readBin = async () => {
                buffer = await e.target.files[0].arrayBuffer();
            };
            readBin().then(() => {
                let byteArray = new Int8Array(buffer);
                console.log("byteArray: ", byteArray);
                // magic number for empty zip
                if (byteArray[0] == 80 && 
                    byteArray[1] == 75 &&
                    byteArray[2] == 5 &&
                    byteArray[3] == 6)
                    {
                        errorMsg = "Empty zip file. Check the contents of the zip file"
                    }
                handleUploadEnd();
            });
        }
        
    }

    const handleOrderAmountChange = (e) => {
        //setOrderAmount(e.target.value);
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setOrderAmount(parseInt(onlyNums, 10))
    }
    

    const handleSaveCase = (executeAfter) => {
        setLock(true);
        let batchData = null;
        let message = "";
        let batchID = "";
        let checkedItemsObj = {};
        let tempSelectedOrders = [];

        if (selectedBatchID.trim() == "")
          message = "Missing batch name.";    
        if (batchDescription.trim() == "")
          message = "Missing batch description.";
        if (message != "")
        {
          openWarningSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{message}</p></div>,300000);
          //setlock(false)
            setOpenLock(true);
          return;
        }
                
        const data = async () => {
            let foundMaterials = [];
            // console.log("BATCH: ", selectedBatch);
            //console.log("tableOrders: ", tableOrders);
            // console.log("props.newBatch: ", props.newBatch);
            //console.log("koneMaterial: ", koneMaterial);
            // console.log("props.batches: ", props.batches);
            // console.log("customRelationships: ", customRelationships);

            let skipModeString = "";
            let skipModeValuesArr = [];
            if (skipModeValues && GetEmpty(skipModeValues) == false) {
                skipModeValues.forEach((val) => {
                    let type = "";
                    if (val["type"].toLowerCase() == "price key")
                    {
                        type = "pricekey:";
                    }
                    else if (val["type"].toLowerCase() == "license")
                    {
                        type = "license:";
                    }
                    skipModeValuesArr.push(type+val["value"]);
                });
            }
            skipModeString = skipModeValuesArr.join(",");
            console.log("skipModeString:", skipModeString);
            if (skipModeString != "")
            {
                executeSettings.set("cws_testing_skip_objects", skipModeString);
            }
            
            if (executeSettings.has("deepScan"))
            {
                if (executeSettings.get("deepScan") == true)
                {
                    executeSettings.set("levelOfScan", "deep_check");
                    executeSettings.set('saveTrace', true);
                }
                else
                {
                    executeSettings.set("levelOfScan", "none");
                }
            }
            else
            {
                executeSettings.set("levelOfScan", "none");
            }
            executeSettings.delete("deepScan");
            executeSettings.forEach((value, key) => (checkedItemsObj[key] = value));
            

            if(GetEmpty(caseSelect) == false)
            {
                if (caseSelect[0]["key"] == "All")
                {
                    orderTitles.filter((a) => (!("defaultTitle" in a) || a["defaultTitle"] == "false")).forEach(element => {
                        tempSelectedOrders.push(GetRunningNumAndPos(element["key"])); // element["key"].slice(-4));
                    });
                }
                else {
                    caseSelect.forEach(element => {
                        tempSelectedOrders.push(GetRunningNumAndPos(element["key"]));
                    });
                }
            }

            let tmpCustomRelationShips = [];
            customRelationships.forEach(element => {
                if (Object.keys(pdmRelationships).includes(element))
                {
                    tmpCustomRelationShips.push(pdmRelationships[element]);
                }
            });

            checkedItemsObj["relationships"] = tmpCustomRelationShips.join(',');
            checkedItemsObj["environment"] = config.environment;
            tempSelectedOrders = tempSelectedOrders.filter((obj2) => obj2 != "All");

            let defCharKeys = [];
            let tmpDefCharacteristics = defChars;
            if (props.newBatch == false)
            {
                tmpDefCharacteristics = selectedBatch["defaultCharacteristics"]
            }
            tmpDefCharacteristics.forEach(element => {
                defCharKeys.push(element["name"]);
            });

            if (tableOrders && GetEmpty(tableOrders) == false) {
                tableOrders.forEach(element => {
                    defCharKeys.forEach(defCharKey => {
                        if (!(defCharKey in element["characteristics"]))
                        {
                            element["characteristics"][defCharKey] = ""
                        }
                    });
                });
            }

            if (props.newBatch == true)
            {
                batchData = await createBatch(instance, selectedBatchID, batchDescription, koneMaterial, koneMaterialRev, tableOrders, defChars, checkedItemsObj, tempSelectedOrders, selectedPDMDescription);
                // console.log("batchData: ", batchData);
                batchData["executeArguments"] = checkedItemsObj;
                batchData["executeOrders"] = tempSelectedOrders;
                batchData["material"] = koneMaterial;
                batchData["materialRevision"] = koneMaterialRev;
                batchData["orders"] = (tableOrders && GetEmpty(tableOrders) == false) ? tableOrders : [];
                let tempBatches = [];
                if (props.batches != null)
                {
                    tempBatches = [...props.batches];
                }
                tempBatches.push(batchData);
                props.setBatches(tempBatches);
            }
            else 
            {
            
             console.log("update batch");   
             console.log(selectedBatch["defaultCharacteristics"]);
                batchData = await updateBatch(instance, selectedBatch["id"], selectedBatchID, batchDescription, tableOrders, selectedBatch["defaultCharacteristics"], checkedItemsObj, tempSelectedOrders, selectedPDMDescription);
                batchData["executeArguments"] = checkedItemsObj;
                batchData["executeOrders"] = tempSelectedOrders;
                batchData["material"] = koneMaterial;
                batchData["materialRevision"] = koneMaterialRev;
                batchData["orders"] = (tableOrders && GetEmpty(tableOrders) == false) ? tableOrders : [];
                let tmpArr = [batchData];
                props.setBatches(props.batches.map(obj => tmpArr.find(o => o.id === obj.id) || obj));
            }    
                 
            // console.log("props.batches2: ", props.batches);             
            // console.log("batchData: ", batchData);   
            batchID = batchData["id"];          
            setSelectedBatch(batchData);
            //console.log(selectedBatch);
        };
        data().catch((error)=>{message += error.message+"\n"}).then(() => {
            if (executeAfter == true) {
                if (caseSelect.length == 0)
                {
                    openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"No orders selected"}</p></div>,300000);
                    //setlock(false)
                    setOpenLock(true);
                    return;
                }
                let executeData = null;

                const execute = async () => {
                    // console.log("CHECKED: ", checkedItemsObj); 
                    executeData = await executeBatch(instance, batchID, tempSelectedOrders, checkedItemsObj, accounts[0].username);
                };
                execute().catch(()=>{message += console.error+"\n"}).then(() => {
                    //console.log("EXECUTEDATA: ", executeData);
                    //dispatch(showSnackbar("Batch saved", "success"));
                    if (message != "")
                    {
                        openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>,300000);
                        //setlock(false)
                        setOpenLock(true);
                    } 
                    else 
                    {
                        openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Batch execute started successfully."}</p></div>,300000);
                        batchData["status"] = "Processing";
                        batchData["lastRun"] = executeData["lastRun"];
                        batchData["lastRunBy"] = executeData["lastRunBy"];
                        batchData["executeArguments"] = executeData["executeArguments"];
                        batchData["executeOrders"] = executeData["executeOrders"];
                        let tmpArr = [batchData];
                        if (props.batches.find(o => o.id == batchData.id))
                        {
                            props.setBatches(props.batches.map(obj => tmpArr.find(o => o.id === obj.id) || obj));
                        }
                        else
                        {
                            let tempBatches = [];
                            if (props.batches != null)
                            {
                                tempBatches = [...props.batches];
                            }
                            tempBatches.push(batchData);
                            props.setBatches(tempBatches);
                        }
                        props.setNewBatch(false);
                        //setlock(false)
                        setOpenLock(true);
                        history.push("/batches");
                    }
                });
            } else {
                if (message != "")
                {
                    openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>,300000);
                    //setlock(false)
                    setOpenLock(true);
                } 
                else 
                {
                    openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Batch saved successfully."}</p></div>,300000);
                    ////setlock(false)
                    setOpenLock(true);
                    props.setNewBatch(false);
                }
            }
            });
        
        setLockFields(true);
        setSaveCase(!saveCase);
    }

    const handleOrderDescriptionChange = (e) => {
        setOrderDescription(e.target.value);
        selectedOrder["description"] = e.target.value;
        console.log("SELECTED: ", selectedOrder);
        console.log("TITLES: ", orderTitles);
        let tempOrders = [...orderTitles];
        tempOrders.forEach(element => {
            if (element["key"] == selectedOrder["name"]){
                element["description"] = e.target.value;
            }
        });
        setOrderTitles(tempOrders);
    }

    // Returns true if the button should be disabled
    const GetExecuteButtonDisabled = () => {
        let ordersEmpty = true;
        if (tableOrders != null)
        {
            if(tableOrders.length > 0)
            {
                ordersEmpty = false;
            }
        }
        return ((GetStringEmpty(koneMaterial) || GetStringEmpty(koneMaterialRev) 
        || GetStringEmpty(selectedBatchID)  || GetStringEmpty(batchDescription)) || lock || ordersEmpty || caseSelect.length == 0);
    }

    const GetSaveButtonDisabled = () => {
        return (lock || GetStringEmpty(koneMaterial) || GetStringEmpty(koneMaterialRev) 
        || GetStringEmpty(selectedBatchID)  || GetStringEmpty(batchDescription));
    }


    function toggleAllChkBox(e) {
        var chk=document.getElementsByName("chk");
        console.log("checked::=="+e.target.checked);
        if(e.target.checked) {
        for(let i=0;i<chk.length;i++) {
        // @ts-ignore
        chk[i].checked=true;
        }} else {
        for(let i=0;i<chk.length;i++) {
        // @ts-ignore
        chk[i].checked=false;
        }}
    }

    const handleExecuteBatch = () => {
        if (caseSelect.length == 0)
        {
            openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"No orders selected"}</p></div>,300000);
            return;
        }
        let executeData = null;
        //const promises = [];
        // if (executeSettings.has("confPDM")){
        //     if (executeSettings.get("confPDM") == "true")
        //     {
        //         executeSettings.set("relationships", customRelationships);
        //     }
        // }

        let checkedItemsObj = {};
        if (executeSettings.has("deepScan"))
        {
            if (executeSettings.get("deepScan") == true)
            {
                executeSettings.set("levelOfScan", "deep_check");
                executeSettings.set('saveTrace', true);
            }
            else
            {
                executeSettings.set("levelOfScan", "none");
            }
        }
        else
        {
            executeSettings.set("levelOfScan", "none");
        }
        executeSettings.delete("deepScan");
        executeSettings.forEach((value, key) => (checkedItemsObj[key] = value));
        let tempSelectedOrders = [];

        console.log("orderTitles: ", orderTitles);
        console.log("caseSelect: ", caseSelect);

        if (caseSelect[0]["key"] == "All")
        {
            orderTitles.filter((a) => (!("defaultTitle" in a) || a["defaultTitle"] == "false")).forEach(element => {
                tempSelectedOrders.push(GetRunningNumAndPos(element["key"]));
            });
        }
        else {
            caseSelect.forEach(element => {
                tempSelectedOrders.push(GetRunningNumAndPos(element["key"]));
            });
        }
        
        let tmpCustomRelationShips = [];
        customRelationships.forEach(element => {
            if (Object.keys(pdmRelationships).includes(element))
            {
                tmpCustomRelationShips.push(pdmRelationships[element]);
            }
        });

        checkedItemsObj["relationships"] = tmpCustomRelationShips.join(',');
        checkedItemsObj["environment"] = config.environment;

        console.log("checkedItemsObj: ", checkedItemsObj);
        console.log("selectedBatch: ", selectedBatch);
        console.log("tempSelectedOrders: ", tempSelectedOrders);
        let message = "";
        const execute = async () => {
            // console.log("CHECKED: ", checkedItemsObj); 
            executeData = await executeBatch(instance, selectedBatch["id"], tempSelectedOrders, checkedItemsObj);
            console.log("executeData: ", executeData); 
            if (executeData["exception"])
            {
                message += executeData["exception"];
            }
            // if (executeData["executeOrders"])
            // {
            //     selectedBatch["executeOrders"] = executeData["executeOrders"]; 
            // }
            // if (executeData["executeArguments"])
            // {
            //     selectedBatch["executeArguments"] = executeData["executeArguments"]; 
            // }
        }; 
        execute().catch((error) => {message = error.message.toString();}).then(() => {
            // console.log("EXECUTEDATA: ", executeData);
            if (message == ""){
                
                openSuccessSnackbar(<div style={{whiteSpace: "nowrap"}}><SuccessIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Batch execute started successfully."}</p></div>,300000);
                selectedBatch["status"] = "Processing";
                let tmpArr = [selectedBatch];
                props.setBatches(props.batches.map(obj => tmpArr.find(o => o.id === obj.id) || obj));
            } else {
                openErrorSnackbar(<div style={{whiteSpace: "nowrap"}}><ErrorIcon style={{display: "inline-block", verticalAlign: "middle", marginRight: "14px"}} className={globalClasses.whiteIcon} /><p style={{display: "inline-block", verticalAlign: "middle", whiteSpace: "pre-wrap"}}>{"Error: " + message}</p></div>,300000);
            }
    
        });
        
        // if (lockFields == false) {
        //     // promises.push(handleSaveCase);
        //     // promises.push(execute);
            
        //     // handleSaveCase().catch(console.error).then(() => {
        //     //     execute().catch(console.error).then(() => {
        //     //         console.log("EXECUTEDATA: ", executeData);
        //     //     });
        //     // });
        // } else {
        //     // promises.push(execute);
            
        //     // execute().catch(console.error).then(() => {
        //     //     console.log("EXECUTEDATA: ", executeData);
        //     // });
        // }
        // Promise.all(promises).then((response) => response.map(res => console.log("RES: ", response))).catch((err) => console.log(err));
    }

    useEffect(() => {

        setOrderTitles(initialTitles);        
        executeSettings.set('deepScan', false);
        executeSettings.set('confPDM', true);
        executeSettings.set('useDefaultCadOptions', true);
        executeSettings.set('sendToCAD', false);
        executeSettings.set('usePdmUpload', false);
        executeSettings.set('saveTrace', false);
        executeSettings.set('structureConversion', false);
        executeSettings.set('selectRawMaterials', false);
        executeSettings.set('doMaterialSubstitution', false);
        executeSettings.set('doMaterialSubstitutionValue', " ");
        
        console.log("UseEffect executeSettings:", executeSettings);
        if (GetEmpty(props.selectedBatch) == false)        
        {
            if (GetEmpty(selectedBatch["executeArguments"]) == false)
            {
                for (const [key, value] of Object.entries(selectedBatch["executeArguments"])) {        
                if (key == "levelOfScan")
                {
                    if (value == "deep_check")
                    executeSettings.set("deepScan", true);
                }
                else
                {
                    if (typeof value === 'string' || value instanceof String)
                    {
                        executeSettings.set(key, (value === 'true'));
                    }
                    else
                    {
                        executeSettings.set(key, (value === true));
                    }
                }
                }
                executeSettings.delete("environment");                
                executeSettings.delete("relationships");   
                console.log("executeSettings edit values:", executeSettings);          
            }

            if (GetEmpty(selectedBatch["executeOrders"]) == false)
            {
                if (GetEmpty(selectedBatch["orders"]) == false)
                {
                    if (props.copyBatch == false)
                    {
                        let initialOrders = [];
                        let regex = new RegExp("(?:" + selectedBatch["executeOrders"].join("|") + ")$");
                        selectedBatch["orders"].map((obj) => {     
                            if(obj["name"].match(regex))
                            {
                                let splitObjNameArr = obj["name"].split("_");
                                if (splitObjNameArr.length > 1)
                                {
                                let splitObjName = splitObjNameArr[splitObjNameArr.length-1];
                                splitObjName = splitObjName.replace(/^0+/, '');
                                initialOrders.push({ key: obj["name"], name: splitObjName, resizable: true, width: "5%", editable: true, description: obj["description"], selected: true })
                            }
                                else
                                {
                                    let splitObjNameArr2 = obj["name"].split("-");
                                    if (splitObjNameArr2.length >= 2)
                                    {
                                        let splitObjName = splitObjNameArr2[splitObjNameArr2.length-2].slice(-4) + "-" + splitObjNameArr2[splitObjNameArr2.length-1];
                                        splitObjName = splitObjName.replace(/^0+/, '');
                                    initialOrders.push({ key: obj["name"], name: splitObjName, resizable: true, width: "5%", editable: true, description: obj["description"], selected: true })
                                    }
                                    else
                                    {
                                        let splitObjName = obj["name"].slice(-4);
                                        splitObjName = splitObjName.replace(/^0+/, '');
                                        initialOrders.push({ key: obj["name"], name: splitObjName, resizable: true, width: "5%", editable: true, description: obj["description"], selected: true })
                                    }
                                }
                            }
                        })
                        if (!initialOrders.some(obj => obj["key"] === "All"))
                        {
                            let newObj = { key: 'All', name: 'All', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "false", description: "" }
                            if (initialOrders.length == selectedBatch["orders"].length)
                            {
                                initialOrders.push(newObj);
                                setOrderAllSelected(true);
                            }
                        }
                        setCaseSelect([...initialOrders]);
                        handleCaseSelectChange(null, initialOrders, null, null);
                        setReRender3(!reRender3);
                        setResetOrders(!resetOrders);
                    }
                }
                
            }
        }
        setExecuteSettings(executeSettings);
                        
    }, []);

    useEffect(() => {
        if (selectedBatch == null) {return;}
        if (props.copyBatch == true)
        {
            let tempOrders = [];
            let newTempOrders = [];
            tempOrders.push(...selectedBatch["orders"]);
            let tmpBatch = selectedBatch;
            tempOrders.forEach(element => {
                let tmpOrderName = element["name"];
                let splitName = element["name"].split("_");
                let newName = "";
                if (splitName.length > 1) {
                    newName = selectedBatchID + "_" + splitName[splitName.length - 1];
                }
                else {
                    let splitName2 = element["name"].split("-");
                    if (splitName2.length >= 2)
                    {
                        newName = selectedBatchID + splitName2[splitName2.length-2].slice(-4) + "-" + splitName2[splitName2.length-1];
                    }
                    else
                    {
                        newName = selectedBatchID + element["name"].slice(-4);
                    }
                }
                element["name"] = newName;
                newTempOrders.push({
                    "batchID": selectedBatchID,
                    "name": newName,
                    "description": "",
                    "status": "Not run",
                    "message": null,
                    "resultFiles": [],
                    "characteristics": element["characteristics"]
                })
            });
            tmpBatch["orders"] = newTempOrders;
            setSelectedBatch(tmpBatch);
            setTableOrders(newTempOrders);
            props.setSelectedBatchOrders(newTempOrders);

            if (GetEmpty(selectedBatch["executeOrders"]) == false)
            {
                if (GetEmpty(selectedBatch["orders"]) == false)
                {   
                    let initialOrders = [];
                    let regex = new RegExp("(?:" + selectedBatch["executeOrders"].join("|") + ")$");
                    selectedBatch["orders"].map((obj) => {     
                        if(obj["name"].match(regex))
                        {
                            let splitObjNameArr = obj["name"].split("_");
                            if (splitObjNameArr.length > 1)
                            {
                            let splitObjName = splitObjNameArr[splitObjNameArr.length-1];
                            splitObjName = splitObjName.replace(/^0+/, '');
                            initialOrders.push({ key: obj["name"], name: splitObjName, resizable: true, width: "5%", editable: true, description: obj["description"], selected: true })
                        }
                            else
                            {
                                let splitObjNameArr2 = obj["name"].split("-");
                                if (splitObjNameArr2.length >= 2)
                                {
                                    let splitObjName = splitObjNameArr2[splitObjNameArr2.length-2].slice(-4) + "-" + splitObjNameArr2[splitObjNameArr2.length-1];
                                    splitObjName = splitObjName.replace(/^0+/, '');
                                initialOrders.push({ key: obj["name"], name: splitObjName, resizable: true, width: "5%", editable: true, description: obj["description"], selected: true })
                                }
                                else
                                {
                                    let splitObjName = obj["name"].slice(-4);
                                    splitObjName = splitObjName.replace(/^0+/, '');
                                    initialOrders.push({ key: obj["name"], name: splitObjName, resizable: true, width: "5%", editable: true, description: obj["description"], selected: true })
                                }
                            }
                            
                        }
                    });
                    if (!initialOrders.some(obj => obj["key"] === "All"))
                    {
                        let newObj = { key: 'All', name: 'All', resizable: true, width: "240px", editable: true, frozen: true, defaultTitle: "false", description: "" }
                        if (initialOrders.length == selectedBatch["orders"].length)
                        {
                            initialOrders.push(newObj);
                            setOrderAllSelected(true);
                        }
                    } 

                    _caseSelect = initialOrders;
                    setCaseSelect([...initialOrders]);
                    handleCaseSelectChange(null, initialOrders, null, null);
                    setReRender3(!reRender3);
                    setResetOrders(!resetOrders);
                } 
            }
        }
        
    }, [selectedBatchID]);

    useEffect(() => {
        if (openLock == true)
        {
            const lockTimeout = setTimeout(() => {
                setLock(false);
                setOpenLock(false);
            }, 1000);

            return () => {clearTimeout(lockTimeout)};
        }
    },[openLock]);

    const triggerFileInput = () => {
        fileInputRef.current.click(); 
    };

    return (
        <div>
            <div className={classes.topButtonsDiv}>
            <Typography variant="h5" color="textPrimary" align="left">
                {'Test batch'}
            </Typography>
                <Box>
                <Tooltip title={"Save batch"} TransitionComponent={({ children }) => children}>
                    <span>
                    <IconButton disabled={GetSaveButtonDisabled()} className={clsx(classes.topButton, globalClasses.blueButton, GetSaveButtonDisabled() && globalClasses.disabledButton)} onClick={() => {
                          handleSaveCase(false);
                        }}>
                        <Save />
                    </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={"Execute batch"} TransitionComponent={({ children }) => children}>
                    <span>
                    <IconButton disabled={GetExecuteButtonDisabled()} className={clsx(classes.topButton, globalClasses.blueButton, GetExecuteButtonDisabled() && globalClasses.disabledButton)} onClick={() => {
                        // console.log("ID: ", selectedBatchID.trim());
                        // console.log("TO: ", tableOrders);
                        // console.log("NEW: ", props.newBatch);
                        // console.log("SELECTED: ", selectedBatch);
                        if (selectedBatchID.trim() != "")
                        {
                            if (props.newBatch == false) //tableOrders == null &&
                            {
                                if (selectedBatch != null){
                                    callGetBatchOrders();
                                }
                            }
                            setOpenExecuteDialog(!openExecuteDialog);
                        }
                        }}>
                        <PlayArrow />
                    </IconButton>
                    </span>
                </Tooltip>
                </Box>
            </div>


            <Paper className={classes.paper}>
                <div className={classes.koneMaterialDiv}>
                    {/* <p>KONE Material: {koneMaterial == null ? "" : koneMaterial["id"] + "/" + koneMaterial["revision"]}</p> */}
                    <p>KONE Material:</p>
                    <FormControl className={classes.root} style={{ padding: "0px", marginLeft: "4px"}} >
                                        {/* <InputLabel shrink={true}>Orders</InputLabel> */}
                                                                 
                                        <Autocomplete 
                                        freeSolo
                                        autoSelect
                                        options={consoleMaterials}
                                        size="small"
                                        disabled={lockFields || lockKoneMaterial} 
                                        value={koneMaterial}
                                        classes={{  input: classes.inputStyle }}
                                        getOptionLabel={(option) => option}
                                        ListboxProps={{ style: { maxHeight: '70vh' } }}
                                        //style={{width: "100%"}}
                                        style={{width: "190px"}}
                                        onChange={(e, obj) => {
                                            console.log("A: ", obj);
                                            setKoneMaterial(obj);
                                            handleKMChange(e, obj);
                                        }}
                                        onKeyUp={(e)=>{
                                            convertToUppercase(e);
                                        }}
                                        
                                        renderOption={(option, {selected}) => (
                                                    <React.Fragment>
                                                        <ListItemText primary={option} />
                                                    </React.Fragment>

                                                )
                                            }
                                        renderInput={(params) => (
                                                <TextField error={GetStringEmpty(koneMaterial)} helperText={GetStringEmpty(koneMaterial) ? "Missing material" : ""} FormHelperTextProps={{
                                                    className: globalClasses.helperText
                                                  }} id="outlined-basic" variant="outlined" {...params} />
                                        )}
                                        />
                                    </FormControl>

                                    {/* <p>Revision</p> */}
                                    <FormControl size="small" style={{paddingRight: "4px", marginLeft: "4px"}}>
                                    <Autocomplete
                                        freeSolo
                                        autoSelect
                                        options={koneMaterialRevisions}
                                        size="small"
                                        style={{ width: "70px" }}
                                        disabled={koneMaterial == "" || lockKoneMaterialRevision ? true : false}
                                        value={koneMaterialRev}
                                        classes={{ input: classes.inputStyle }}
                                        getOptionLabel={(option) => option}
                                        ListboxProps={{ style: { maxHeight: '70vh' } }}
                                        onChange={(e, obj) => {
                                        setKoneMaterialRev(obj);
                                        }}
                                        onKeyUp={(e)=>{
                                            convertVersionToUppercase(e);
                                        }}

                                        renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <ListItemText primary={option} />
                                        </React.Fragment>
                                        )
                                        }
                                        renderInput={(params) => (
                                        <TextField error={GetStringEmpty(koneMaterialRev)} helperText={GetStringEmpty(koneMaterialRev) ? "Missing revision" : ""} FormHelperTextProps={{
                                            className: globalClasses.helperText
                                          }} id="outlined-basic" variant="outlined" {...params} />
                                        )}
                                    />
                                
                                </FormControl>
                                <Tooltip title={"Fetch from PDM"}>       
                                    <span>
                                        
                                    <Button size="small" disabled={GetStringEmpty(koneMaterial) || GetStringEmpty(koneMaterialRev)} className={clsx(globalClasses.blueButton, classes.generateIdButton, (GetStringEmpty(koneMaterial) || GetStringEmpty(koneMaterialRev)) && globalClasses.disabledButton)} onClick={() => { 
                                            console.log("KM: ", koneMaterial);
                                            console.log("KMRev: ", koneMaterialRev);
                                            if (koneMaterial != null && koneMaterialRev != null && koneMaterial != "" && koneMaterialRev != "")
                                            {
                                                if((props.newBatch == true || props.copyBatch == true) && selectedBatchID.trim() == "")
                                                    getPDMdata();
                                                    
                                                if (testOrdersOpen == false)
                                                    setTestOrdersOpen(true);
                                                
                                                getPDMDescription();
                                                getCharacteristics();
                                                forceUpdate();
                                                setToggleAll(true);
                                                setCallToggleAll(true);
                                                caseSelect.length = selectedBatch["orders"].length;
                                            }
                                        }
                                    }>
                                        
                                        <Refresh />
                                    </Button>
                                    </span>
                                    </Tooltip>
                                    <p className={classes.testInfoItem} style={{paddingLeft: "75px"}}>Description:</p>
                                    <Tooltip title={selectedPDMDescription == null ? "" : selectedPDMDescription} TransitionComponent={({ children }) => children}>
                                        <TextField FormHelperTextProps={{
                                                            className: globalClasses.helperText
                                                        }} InputProps={{ classes: { input: classes.inputStyle } }} disabled id="outlined-basic" value={selectedPDMDescription} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoDesc)} />
                                    </Tooltip>
                </div>
                <div className={classes.testInfoDiv}>

                    <p className={classes.testInfoItem}>Name:</p>
                    {/* <Tooltip title={testName} TransitionComponent={({ children }) => children}>
                        <TextField id="outlined-basic" defaultValue={testName} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoName)} />
                    </Tooltip> */}

                    <Tooltip title={selectedBatchID == null ? "" : selectedBatchID} TransitionComponent={({ children }) => children}>
                        <TextField error={GetStringEmpty(selectedBatchID)} helperText={GetStringEmpty(selectedBatchID) ? "Missing batch name" : ""} FormHelperTextProps={{
                                            className: globalClasses.helperText
                                          }} InputProps={{ classes: { input: classes.inputStyle } }} disabled id="outlined-basic" value={selectedBatchID} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoID)} />
                    </Tooltip>
                    

                    <p className={classes.testInfoItem} style={{paddingLeft: "75px"}}>Batch Description:</p>
                    <Tooltip title={batchDescription == null ? "" : batchDescription} TransitionComponent={({ children }) => children}>
                        <TextField error={GetStringEmpty(batchDescription)} helperText={GetStringEmpty(batchDescription) ? "Missing batch description" : ""} FormHelperTextProps={{
                                            className: globalClasses.helperText
                                          }} id="outlined-basic" InputProps={{ classes: { input: classes.inputStyle } }} defaultValue={batchDescription} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem, classes.testInfoDesc)} onChange={(e) => {setBatchDescription(e.target.value);}} />
                    </Tooltip>
                </div>
            </Paper>
            {/* DIALOG */}
            <ExecuteDialog 
                open={openExecuteDialog}
                setOpen={setOpenExecuteDialog}
                toggleAll={toggleAll}
                setToggleAll={setToggleAll}
                orderTitles={orderTitles}
                setOrderTitles={setOrderTitles}
                copyBatch={props.copyBatch}
                caseSelect={caseSelect}
                setCaseSelect={setCaseSelect}
                selectedBatchOrders={selectedBatchOrders}
                selectedBatch={selectedBatch}
                setSelectedBatch={setSelectedBatch}
                selectedKoneMaterial={koneMaterial}
                selectedKoneMaterialRev={koneMaterialRev}
                selectedBatchID={selectedBatchID}
                batchDescription={batchDescription}
                selectedPDMDescription={selectedPDMDescription}
                newBatch={props.newBatch}
                batches={props.batches}
                setBatches={props.setBatches}
                setNewBatch={props.setNewBatch}
                setExecuteSettings={setExecuteSettings}
                mode={"batch"}
                defChars={defChars}
                tableOrders={tableOrders}
                setSkipModeValues={setSkipModeValues}
                                    />
            <Box className={clsx(classes.paper, classes.paper2)}>
                {/* <div className={classes.testButtonDiv}>
                    <Button disableRipple startIcon={
                        !testOptionsOpen ? <ArrowRight /> : <ArrowDropDown />
                    } className={classes.testButton} variant="outlined" onClick={handleSetTestOptionsOpen}>Test options</Button>
                </div> */}

                {/* <Grid container className={clsx(classes.shown, !testOptionsOpen && classes.hidden)}>
                    {testOptionsOpen ? BatchOptionCheckBoxes.map(item => (
                        <Grid key={item.key} item xs={xsVal} sm={smVal} md={mdVal} className={classes.testOptionsItem}>
                            <label>
                                <BatchOptionCheckbox className={classes.testOptionCheckBox} label={item.label} name={item.name} checked={checkedItems.get(item.name)} onChange={(e) => handleChange(e)} />
                            </label>
                        </Grid>
                    )) : null}
                    <Grid item xs={xsVal} sm={smVal} md={mdVal} className={classes.testOptionsItem}>
                        {testOptionsOpen ?
                            <Button variant="contained" className={classes.blueButton}>
                                EDIT CAD OUTPUT OPTIONS
                    </Button>
                            : null}
                    </Grid>
                </Grid> */}


                {/* </Paper>

            <Paper className={classes.paper}> */}
                <Box className={classes.casesButtonsBox}>
                    <div className={classes.testButtonDiv}>
                        <Button disableRipple startIcon={
                            !testOrdersOpen ? <ArrowRight /> : <ArrowDropDown />
                        } className={classes.testButton} variant="outlined" onClick={handleSetTestCasesOpen}>Test orders</Button>
                    
                    {koneMaterial != null && koneMaterialRev != null && koneMaterial != "" && koneMaterialRev != "" && lockFields && <Tooltip title={"Update Characteristics"}>       
                        <span style={{marginTop: "9%"}}>
                        <Button size="small" disabled={GetStringEmpty(koneMaterial) || GetStringEmpty(koneMaterialRev)} className={clsx(globalClasses.blueButton, classes.generateIdButton, (GetStringEmpty(koneMaterial) || GetStringEmpty(koneMaterialRev)) && globalClasses.disabledButton)} onClick={() => {
                                if (koneMaterial != null && koneMaterialRev != null && koneMaterial != "" && koneMaterialRev != "")
                                {
                                    getLatestPDMdata();
                                        
                                    if (testOrdersOpen == false)
                                        setTestOrdersOpen(true);
                                    
                                    getPDMDescription();
                                    getCharacteristics();
                                    forceUpdate();
                                    confirmUpdate();
                                }
                            }
                        }>
                            <CachedIcon />
                        </Button>
                        </span>
                        </Tooltip>
                    }
                    </div>
                    <div className={classes.testCasesButtonsDiv}>
                        {testOrdersOpen ?
                         // <IconButton variant="contained" className={clsx(classes.testCasesButton, classes.blueButton, classes.addCaseButton)} onClick={handleAddCase}>
                            //     <AddIcon />
                            //     <div>
                            //         Add case
                            //     </div>
                            // </IconButton> 
                            <>
                            {isLoading && <CircularProgress />}
                            <div>{uploadMessage && <p>{uploadMessage}</p>}</div>
                            <FormControlLabel
                                control={<Checkbox style={{color: "#0071B9", marginRight: "8px"}} className={globalClasses.checkbox} checked={toggleAll} onChange={() => {
                                    setToggleAll(!toggleAll);
                                    setCallToggleAll(!callToggleAll);
                                }} onClick={e => {toggleAllChkBox(e);}} name="ToggleAll" />}
                                label="Execute all orders"
                                style={{marginRight: "12px"}}
                                labelPlacement='start'
                                 
                            />
                            <TextField
                            id="outlined-number"
                            type="number"
                            onChange={
                                handleOrderAmountChange
                            }
                            style = {{width: 48, marginRight: "12px"}}
                            //defaultValue={1}
                            value={orderAmount}
                            InputProps={{
                                inputProps: { 
                                    max: 999, min: 1 
                                }
                            }} 
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                            {/* <FormControl size="small" style={{paddingRight: "20px"}}>
                                <Select
                                labelId="outlined-basic"
                                //id="SelectOrderAmount"
                                id="outlined-basic" 
                                variant="outlined" 
                                value={orderAmount}
                                
                                onChange={handleOrderAmountChange}
                                MenuProps={{
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                      },
                                    getContentAnchorEl: null,
                                    autoFocus: false,
                                  }}
                                >
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                                </FormControl> */}
                                {/* <NativeSelect
                                id="SelectOrderAmount"
                                value={orderAmount}
                                onChange={handleOrderAmountChange}
                                >
                                    <option value={1}>1</option>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                </NativeSelect> */}
                            <Button variant="contained" disabled={selectedBatchID == ""} className={clsx(classes.testCasesButton, globalClasses.blueButton, classes.addCaseButton)} onClick={handleAddCase}>
                                <AddIcon className={classes.addCaseIcon} /> Add order
                            </Button>
                            <input
                                type="file"
                                accept=".txt"
                                onChange={handleFileUpload}
                                ref={fileInputRef}
                                style={{ display: 'none' }}  // Hide the file input
                            />
                            <Button variant="contained" disabled={selectedBatchID == ""} className={clsx(classes.testCasesButton, globalClasses.blueButton, classes.addCaseButton)} onClick={() => { triggerFileInput();}}>
                                                <AddIcon className={classes.addCaseIcon} /> Order From File
                                            </Button>
                            </>
                            : null}

                    </div>
                </Box>

                {testOrdersOpen ?
                    <Box>
                        <Box className={clsx(classes.dataSheet, classes.shown, !testOrdersOpen && classes.hidden)}>
                                <CustomDataSheet
                                    addCase={reRender}
                                    orderAmount={orderAmount}
                                    caseSelect={caseSelect}
                                    setCaseSelect={setCaseSelect}
                                    setToggleAll={setToggleAll}
                                    handleCaseSelectChange={handleCaseSelectChange}
                                    toggleAll={toggleAll}
                                    callToggleAll={callToggleAll}
                                    setGrid={setGrid}
                                    grid={grid}
                                    titles={orderTitles}
                                    setTitles={setOrderTitles}
                                    tableOrders={tableOrders}
                                    setTableOrders={setTableOrders}
                                    excelPath={props.koneMaterial != "" ? setCorrectMat(props.koneMaterial) : ""}
                                    materialName={props.koneMaterial}
                                    selectedBatch={selectedBatch}
                                    selectedBatchID={selectedBatchID}
                                    selectedOrders={selectedBatchOrders}
                                    ordersLoaded={ordersLoaded}
                                    setOrdersLoaded={setOrdersLoaded}
                                    koneMaterial={koneMaterial}
                                    koneMaterialRev={koneMaterialRev}
                                    saveExcel={exportExcel}
                                    loadExcel={importExcel}
                                    excelInputFile={excelInputFile}
                                    saveCase={saveCase}
                                    savedExcelData={getExcelData}
                                    initialExcel={excelData}
                                    initDone={true}
                                    newBatch={props.newBatch}
                                    defChars={defChars}
                                    setdefChars={setdefChars}

                                    setPopoverID={setPopoverID}
                                    setPopoverOpen={setPopoverOpen}
                                    setPopoverAnchorEl={setPopoverAnchorEl}
                                    setOrderDescription={setOrderDescription}
                                    setSelectedOrder={setSelectedOrder}
                                    populateTableWithApiResponse={setTableData}
                                    tableData={tableData}
                                    apiResponse={apiResponse}
                                    //reRender={reRender4}
                                />

                        </Box>
                        <Box className={classes.testCasesButtonsDivBottom}>
                            <Tooltip title={"Save to file (.xlsx)"} TransitionComponent={({ children }) => children}>
                                <IconButton className={clsx(classes.testCasesButton, globalClasses.blueButton)} onClick={handleExportClick}>
                                    <SaveAlt />
                                </IconButton>
                            </Tooltip>
                            <input type='file' id='file' ref={excelInput} onChange={handeExcelInputFileChange} style={{display: 'none'}}/>
                            <Tooltip title={"Load from file (.xlsx)"} TransitionComponent={({ children }) => children}>
                                <IconButton className={clsx(classes.testCasesButton, globalClasses.blueButton)} onClick={handleImportClick}>
                                    <Publish />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>

                    :
                    null}
                    <Popover
                    id={popoverID}
                    open={popoverOpen}
                    anchorEl={popoverAnchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    >
                    <div className={classes.orderPopup}>
                    
                    <div className={classes.orderPopupItem}>
                    <p className={classes.testInfoItem} style={{paddingLeft: "16px"}}>Description:</p>
                    <Tooltip title={orderDescription} TransitionComponent={({ children }) => children}>
                        <TextField id="outlined-basic" InputProps={{ classes: { input: classes.inputStyle } }} defaultValue={orderDescription} variant="outlined" size="small" className={clsx(classes.textField, classes.testInfoItem)} onChange={(e)=>{handleOrderDescriptionChange(e);}} />
                    </Tooltip>
                    </div>

                    </div>
                </Popover>
            </Box>

            {/* <Dialog
            open={openSaveDialog}
            onClose={handleCloseSaveDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Save batch?"}
            </DialogTitle> */}
            {/* <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {"Save Batch?"}
                </DialogContentText>
            </DialogContent> */}
            {/* <DialogActions>
            <Button onClick={()=>{
                if (selectedBatchID.trim() != "")
                {
                    handleSaveCase(false);
                    handleCloseSaveDialog();
                }
            }} autoFocus>
                OK
            </Button>
            <Button onClick={handleCloseSaveDialog}>Cancel</Button>
            </DialogActions>
        </Dialog> */}
        </div>
    );
}
